import styled from 'styled-components';
import { Icon } from 'antd';

const LogoCss = `
  margin: auto 0;
  margin-top: -5px;
  margin-right: 20px;
`;

export const LogoImg = styled.img`
  ${LogoCss}
`;

export const LogoIcon = styled(Icon)`
  ${LogoCss}
`;
