import * as React from 'react';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import * as S from './styles';

type Props = {};

const FullPage: React.FC<Props> = ({ children }) => (
  <S.Wrap>
    <Header />
    <S.Section>
      <Sidebar />
      <S.Content>{children}</S.Content>
    </S.Section>
  </S.Wrap>
);

export default FullPage;
