import styled from 'styled-components';
import { Button as ANTDButton } from 'antd';

export const Button = styled(ANTDButton)<{ linkbutton: string }>`
  border-radius: ${({ linkbutton }) => (linkbutton === 'true' ? 0 : 25)}px;
  border: ${({ linkbutton }) => (linkbutton === 'true' ? 'none' : '1px dashed #f3f3f3')};
  margin-right: ${({ linkbutton }) => (linkbutton === 'true' ? 0 : 15)}px;
  box-shadow: ${({ linkbutton }) =>
    linkbutton === 'true' ? 'none' : '0 0 10px 0 rgba(0, 0, 0, 0.05)'};
  min-width: 120px;
  min-height: 41px;
  box-sizing: border-box;
  font-size: ${({ linkbutton }) => (linkbutton === 'true' ? 14 : 12)}px;
  letter-spacing: -0.36px;
  line-height: 19px;
  color: ${({ linkbutton }) => (linkbutton === 'true' ? 'blue' : 'black')};
  background-color: transparent;
  cursor: pointer;
  display: inline;
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
