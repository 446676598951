import * as React from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { FullPage, Centered } from 'components/Layout';
import * as Routes from './routes';

type LayoutRouteProps = {
  Component: React.ComponentType<any>;
} & RouteProps;

const CenterLayoutRoute = ({ Component, ...props }: LayoutRouteProps) => (
  <Route
    {...props}
    render={(matchProps) => (
      <Centered>
        <Component {...matchProps} />
      </Centered>
    )}
  />
);

const FullPageLayoutRoute = ({ Component, ...props }: LayoutRouteProps) => (
  <Route
    {...props}
    render={(matchProps) => (
      <FullPage>
        <Component {...matchProps} />
      </FullPage>
    )}
  />
);

const Router = () => (
  <Switch>
    <CenterLayoutRoute exact path="/login" Component={Routes.Login} />
    <Redirect exact from="/" to="/dashboard" />
    <FullPageLayoutRoute path="/dashboard" Component={Routes.Dashboard} />
    <FullPageLayoutRoute path="/storefronts" Component={Routes.Storefronts} />
    {/* <Route
      exact
      path="/users/:id"
      render={(props: any) => (
        <Redirect to={`/admin/users/${props.match.params.id}`} />
      )}
    />
    
    */}
    <FullPageLayoutRoute path="/Orders" Component={Routes.Orders} />
    <FullPageLayoutRoute path="/messages" Component={Routes.Messages} />
    <FullPageLayoutRoute path="/users" Component={Routes.Users} />
    <FullPageLayoutRoute path="/tags" Component={Routes.Tags} />
    <FullPageLayoutRoute path="/discounts" Component={Routes.Discounts} />
    <FullPageLayoutRoute path="/storefront/:id" Component={Routes.StorefrontDetail} />
    <FullPageLayoutRoute Component={() => <div>Not found</div>} />
  </Switch>
);

export default withErrorBoundary(Router);
