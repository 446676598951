import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date scalar type */
  Date: any;
  fulfillmentJSON: any;
  categoryJSON: any;
  fulfillmentMethodJSON: any;
  mediaSortOrderJSON: any;
  catalogSortOrderJSONB: any;
  visualTagsJSONB: any;
  adminCategoryJSON: any;
  measuredStatistics: any;
  profileJSON: any;
  meta: any;
  usageMessageJSON: any;
  payloadJSON: any;
  restrictUseJSON: any;
  twilioPayloadJSON: any;
  messageStatusByUserJSON: any;
  SiteMapBrowseType: any;
  SiteMapShopType: any;
  SiteMapCategoryType: any;
  SiteMapCatalogType: any;
  Upload: any;
  attributionJSON: any;
  catalogSortOrderJSON: any;
};


export type AdminCategory = {
  __typename?: 'AdminCategory';
  id: Scalars['Int'];
  title: Scalars['String'];
  resultTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  icon: Scalars['String'];
  uri: Scalars['String'];
  rank: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
  canStorefrontAssign: Scalars['Boolean'];
  isSeasonal: Scalars['Boolean'];
  seasonStartDate?: Maybe<Scalars['Date']>;
  seasonEndDate?: Maybe<Scalars['Date']>;
  isMarketing: Scalars['Boolean'];
};


export type AdminConversationDetail = {
  __typename?: 'AdminConversationDetail';
  conversationHeader: AdminConversationDetailHeader;
  conversationParticipants: AdminConversationParticipants;
  conversationMessages?: Maybe<Array<Maybe<AdminConversationDetailMessage>>>;
};

export type AdminConversationDetailHeader = {
  __typename?: 'AdminConversationDetailHeader';
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at?: Maybe<Scalars['Date']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
};

export type AdminConversationDetailMessage = {
  __typename?: 'AdminConversationDetailMessage';
  conversationMessageId: Scalars['Int'];
  created_at: Scalars['Date'];
  messageIndex: Scalars['Int'];
  sentByUserId: Scalars['ID'];
  outReachType?: Maybe<Scalars['Int']>;
  hasMedia: Scalars['Boolean'];
  body?: Maybe<Scalars['String']>;
  twilioMessageSid: Scalars['String'];
  twilioPayload?: Maybe<Scalars['twilioPayloadJSON']>;
  messageStatusByUserJSON?: Maybe<Scalars['messageStatusByUserJSON']>;
  relatedTwilioMessageSid?: Maybe<Scalars['String']>;
  adminStatus?: Maybe<ConversationAdminStatus>;
};

export type AdminConversationList = {
  __typename?: 'AdminConversationList';
  buyerUser: User;
  sellerUser: ConversationUserWithStorefront;
  lastOutReach?: Maybe<AdminConversationDetailMessage>;
  lastMessageBuyer?: Maybe<AdminConversationDetailMessage>;
  lastMessageSeller?: Maybe<AdminConversationDetailMessage>;
  outReachStatistics?: Maybe<AdminOutReachStatistics>;
  adminHasConversationWithBuyer?: Maybe<HasConversationWithAdmin>;
  adminHasConversationWithSeller?: Maybe<HasConversationWithAdmin>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics?: Maybe<AdminConversationStatistics>;
};

export type AdminConversationListCommon = {
  __typename?: 'AdminConversationListCommon';
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics?: Maybe<AdminConversationStatistics>;
};

export enum AdminConversationOrderBy {
  Lastmessagedate = 'LASTMESSAGEDATE',
  Lastoutreachdate = 'LASTOUTREACHDATE',
  Lastsellermessagedate = 'LASTSELLERMESSAGEDATE',
  Outreachstatisticstotal = 'OUTREACHSTATISTICSTOTAL',
  Adminstatus = 'ADMINSTATUS'
}

export enum AdminConversationOrderByOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AdminConversationParticipants = {
  __typename?: 'AdminConversationParticipants';
  conversationOwnerUser: UserAdmin;
  conversationOtherUser: UserAdmin;
};

export type AdminConversationStatistics = {
  __typename?: 'AdminConversationStatistics';
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type AdminConversationUpdateStatus = {
  conversationId: Scalars['Int'];
  adminConversationStatus?: Maybe<ConversationAdminStatus>;
};

export type AdminConversationUser = {
  __typename?: 'AdminConversationUser';
  conversationUserType: UserType;
  unReadMessageCountByUser?: Maybe<Scalars['Int']>;
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type AdminCsConversationList = {
  __typename?: 'AdminCSConversationList';
  adminStatus?: Maybe<ConversationAdminStatus>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<ConversationMessage>;
  otherUser: ConversationUser;
  storefrontJSON?: Maybe<ConversationListStorefront>;
  unReadMessageCountByUser: Scalars['Int'];
};

export type AdminDeleteUser = {
  __typename?: 'AdminDeleteUser';
  deletedUserId: Scalars['ID'];
  deletedStorefrontId?: Maybe<Scalars['Int']>;
};

export type AdminDeleteUserInput = {
  uid: Scalars['String'];
};

export type AdminDiscountCode = {
  __typename?: 'AdminDiscountCode';
  discountCodeId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  code: Scalars['String'];
  prefix: Scalars['String'];
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  orderMinimumAmount: Scalars['Float'];
  activeFrom: Scalars['Date'];
  activeTo?: Maybe<Scalars['Date']>;
  restrictUseJSON?: Maybe<Scalars['restrictUseJSON']>;
  isAdmin: Scalars['Boolean'];
  isReferral: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  ActiveStatus: Scalars['String'];
  adminNotes?: Maybe<Scalars['String']>;
  maximumDiscountAmount?: Maybe<Scalars['Float']>;
};

export type AdminDiscountCodeInput = {
  prefix: Scalars['String'];
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  orderMinimumAmount: Scalars['Float'];
  activeFrom: Scalars['Date'];
  activeTo?: Maybe<Scalars['Date']>;
  restrictUseJSON?: Maybe<Scalars['restrictUseJSON']>;
  usageMessageJSON?: Maybe<Scalars['usageMessageJSON']>;
  adminNotes?: Maybe<Scalars['String']>;
  maximumDiscountAmount?: Maybe<Scalars['Float']>;
  isReferral?: Maybe<Scalars['Boolean']>;
};

export type AdminDiscountCodeUpdateInput = {
  discountCodeId: Scalars['Int'];
  activeFrom: Scalars['Date'];
  activeTo?: Maybe<Scalars['Date']>;
  adminNotes?: Maybe<Scalars['String']>;
};

export type AdminNotificationCenter = {
  __typename?: 'AdminNotificationCenter';
  notificationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at?: Maybe<Scalars['Date']>;
  type: NotificationType;
  userType?: Maybe<UserType>;
  payload: Scalars['payloadJSON'];
  expiry?: Maybe<Scalars['Date']>;
  cohort?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  countOfUsers: Scalars['Int'];
};

export type AdminNotificationInput = {
  type: NotificationType;
  destination?: Maybe<Scalars['Int']>;
  cohortId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  payload: Scalars['payloadJSON'];
};

export type AdminOutReachListBuyer = {
  __typename?: 'AdminOutReachListBuyer';
  otherUser: User;
  conversationOtherUser: AdminConversationUser;
  lastMessage?: Maybe<AdminConversationDetailMessage>;
  lastMessageOtherUser?: Maybe<AdminConversationDetailMessage>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics?: Maybe<AdminConversationStatistics>;
};

export type AdminOutReachListSeller = {
  __typename?: 'AdminOutReachListSeller';
  otherUser: User;
  conversationOtherUser: AdminConversationUser;
  lastMessage?: Maybe<AdminConversationDetailMessage>;
  lastMessageOtherUser?: Maybe<AdminConversationDetailMessage>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics?: Maybe<AdminConversationStatistics>;
};

export type AdminOutReachStatistics = {
  __typename?: 'AdminOutReachStatistics';
  totalOutReachCount?: Maybe<Scalars['Int']>;
};

export type AdminStorefront = {
  __typename?: 'AdminStorefront';
  statistics: StorefrontStatistics;
  address: Scalars['String'];
  zipCode: Scalars['String'];
  chatrooms: Array<AdminStorefrontChatroom>;
  hasPaymentSetup: Scalars['Boolean'];
  storefrontTags?: Maybe<Array<Maybe<AdminStorefrontTag>>>;
  startPauseDate?: Maybe<Scalars['Date']>;
  endPauseDate?: Maybe<Scalars['Date']>;
  lastInstaTokenUpdate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  owner: User;
  category?: Maybe<Category>;
  priceType: PriceType;
  status: StorefrontStatus;
  businessName: Scalars['String'];
  tagline: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Float'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  photos: StorefrontStorageInfo;
  dynamicLink?: Maybe<Scalars['String']>;
  priceHigh?: Maybe<Scalars['Float']>;
  detail?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  created_at: Scalars['Date'];
  outsideGeofenceRadius?: Maybe<Scalars['Boolean']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  instaUserId?: Maybe<Scalars['String']>;
  instaAccessToken?: Maybe<Scalars['String']>;
  geofenceRadius: Scalars['Float'];
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
  catalog?: Maybe<Array<Maybe<Catalog>>>;
  catalogSummary?: Maybe<Array<Maybe<CatalogSummary>>>;
  catalogSortOrder?: Maybe<Scalars['catalogSortOrderJSONB']>;
  visualTagsJSON?: Maybe<Scalars['visualTagsJSONB']>;
  approvalDate?: Maybe<Scalars['Date']>;
  adminCategoryJSON?: Maybe<Scalars['adminCategoryJSON']>;
  measuredStatistics?: Maybe<Scalars['measuredStatistics']>;
};


export type AdminStorefrontDistanceArgs = {
  filter?: Maybe<DistanceStorefrontFilter>;
};


export type AdminStorefrontMeasuredStatisticsArgs = {
  filter?: Maybe<StatisticsFilter>;
};

export type AdminStorefrontChatroom = {
  __typename?: 'AdminStorefrontChatroom';
  id: Scalars['Int'];
  otherUser: UserAdmin;
  link?: Maybe<Scalars['String']>;
};

export type AdminStorefrontPrimaryImageInput = {
  id: Scalars['Int'];
  primaryImage: Scalars['String'];
};

export type AdminStorefrontTag = {
  __typename?: 'AdminStorefrontTag';
  tagId: Scalars['Int'];
  tag: Scalars['String'];
  isAutoTag?: Maybe<Scalars['Boolean']>;
};

export type AdminTag = {
  __typename?: 'AdminTag';
  tagId: Scalars['Int'];
  tag: Scalars['String'];
  categories?: Maybe<Array<Maybe<AdminTagCategory>>>;
  storefronts?: Maybe<Array<Maybe<AdminTagStorefront>>>;
};

export type AdminTagCategory = {
  __typename?: 'AdminTagCategory';
  categoryId: Scalars['Int'];
  categoryTitle: Scalars['String'];
};

export type AdminTagStorefront = {
  __typename?: 'AdminTagStorefront';
  storefrontId: Scalars['Int'];
  businessName: Scalars['String'];
  isAutoTag?: Maybe<Scalars['Boolean']>;
};

export type AdminTagSummary = {
  __typename?: 'AdminTagSummary';
  tagId: Scalars['Int'];
  tag: Scalars['String'];
  countOfStorefronts: Scalars['Int'];
  categories?: Maybe<Array<Maybe<AdminTagCategory>>>;
};

export type ApplyDiscountCodetoInvoiceInput = {
  id: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  discountCode: Scalars['String'];
};


export type BuyerOrder = {
  __typename?: 'BuyerOrder';
  buyerOrderId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  buyer: User;
  storefront: Storefront;
  seller: User;
  neededBy?: Maybe<Scalars['String']>;
  additionalNotes?: Maybe<Scalars['String']>;
  fulfillmentMethod: FulfillmentMethod;
  status: BuyerOrderStatus;
  productJSON: Array<Maybe<BuyerOrderProductJson>>;
  submittedAt?: Maybe<Scalars['Date']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  declinedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type BuyerOrderProductJson = {
  __typename?: 'BuyerOrderProductJSON';
  productId: Scalars['Int'];
  productMedia?: Maybe<Array<Maybe<Media>>>;
  name: Scalars['String'];
  priceType: CatalogPriceType;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  fulfillmentMethod: FulfillmentMethod;
};

export enum BuyerOrderStatus {
  Saved = 'SAVED',
  Submitted = 'SUBMITTED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type Catalog = {
  __typename?: 'Catalog';
  catalogId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  storefrontId: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  priceType: CatalogPriceType;
  price: Scalars['Float'];
  pricingDescription?: Maybe<Scalars['String']>;
  status: CatalogStatus;
  fulfillmentMethodJSON?: Maybe<Scalars['fulfillmentMethodJSON']>;
  mediaSortOrder?: Maybe<Scalars['mediaSortOrderJSON']>;
  allowSelectQuantity: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Date']>;
  media?: Maybe<Array<Maybe<Media>>>;
};

export type CatalogMediaInput = {
  storefrontId: Scalars['Int'];
  catalogId: Scalars['Int'];
  mediaId: Scalars['Int'];
  status: MediaStatus;
};

export enum CatalogPriceType {
  Fixed = 'FIXED',
  Hourly = 'HOURLY'
}

export type CatalogSortOrderInput = {
  catalogSortOrder: Scalars['catalogSortOrderJSON'];
};



export enum CatalogStatus {
  Saved = 'SAVED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED'
}

export type CatalogSummary = {
  __typename?: 'CatalogSummary';
  catalogStatus: CatalogStatus;
  count: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  title: Scalars['String'];
  resultTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  storefronts: Array<Storefront>;
  active?: Maybe<Scalars['Boolean']>;
  icon: Scalars['String'];
  uri: Scalars['String'];
  rank: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
  canStorefrontAssign: Scalars['Boolean'];
};


export type CategoryTaxonomy = {
  __typename?: 'CategoryTaxonomy';
  taxonomyId: Scalars['Int'];
  title: Scalars['String'];
  family: Scalars['String'];
  level: Scalars['Int'];
  index: Scalars['Int'];
  uriSlug: Scalars['String'];
  metaTags: Scalars['String'];
  description: Scalars['String'];
  longDescription: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  categoryChildren?: Maybe<Array<Maybe<CategoryTaxonomyChildren>>>;
  meta?: Maybe<Scalars['meta']>;
};

export type CategoryTaxonomyCategoryDetail = {
  __typename?: 'CategoryTaxonomyCategoryDetail';
  id: Scalars['Int'];
  title: Scalars['String'];
  uriSlug: Scalars['String'];
};

export type CategoryTaxonomyChildren = {
  __typename?: 'CategoryTaxonomyChildren';
  id: Scalars['Int'];
  icon: Scalars['String'];
  title: Scalars['String'];
  uriSlug: Scalars['String'];
  meta?: Maybe<Scalars['meta']>;
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['Int'];
  firstDisplayName: Scalars['String'];
  secondDisplayName: Scalars['String'];
  firstUser: User;
  secondUser: User;
};

export type Cohort = {
  __typename?: 'Cohort';
  cohortId: Scalars['Int'];
  created_at: Scalars['Date'];
  cohort: Scalars['String'];
  cohortDescription: Scalars['String'];
  userType?: Maybe<UserType>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['Int'];
  user: User;
  storefront: Storefront;
  body: Scalars['String'];
  parent?: Maybe<Comment>;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  reactions?: Maybe<Scalars['Int']>;
  didIReact?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  sellerResponse?: Maybe<Scalars['String']>;
  order?: Maybe<CommentOrder>;
  conversation?: Maybe<CommentConversation>;
};


export type CommentReactionsArgs = {
  filter?: Maybe<ReactionsFilter>;
};


export type CommentDidIReactArgs = {
  filter?: Maybe<ReactionsFilter>;
};

export type CommentConversation = {
  __typename?: 'CommentConversation';
  conversationId?: Maybe<Scalars['Int']>;
};

export type CommentOrder = {
  __typename?: 'CommentOrder';
  serviceName?: Maybe<Scalars['String']>;
};

export type CompleteOrderInput = {
  id: Scalars['Int'];
  serviceName: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  extraUnits?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  additionalCost?: Maybe<Scalars['Float']>;
  pricingDetail?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  telephone: Scalars['String'];
  email: Scalars['String'];
};

export enum ConversationAdminStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type ConversationCreated = {
  __typename?: 'ConversationCreated';
  conversationId: Scalars['Int'];
  relatedConversationId?: Maybe<Scalars['Int']>;
};

export type ConversationList = {
  __typename?: 'ConversationList';
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  twilioConversationSid?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<ConversationMessage>;
  otherUser: ConversationUser;
  storefrontJSON?: Maybe<ConversationListStorefront>;
  unReadMessageCountByUser: Scalars['Int'];
};

export type ConversationListStorefront = {
  __typename?: 'ConversationListStorefront';
  id: Scalars['Int'];
  businessName: Scalars['String'];
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  created_at: Scalars['Date'];
  messageIndex: Scalars['Int'];
  sentByUserId: Scalars['ID'];
  hasMedia: Scalars['Boolean'];
  body?: Maybe<Scalars['String']>;
  twilioMessageSid: Scalars['String'];
};

export enum ConversationType {
  Buyerseller = 'BUYERSELLER',
  Buyerbuyer = 'BUYERBUYER',
  Sellerseller = 'SELLERSELLER',
  Sellerbuyer = 'SELLERBUYER',
  Adminbuyer = 'ADMINBUYER',
  Adminseller = 'ADMINSELLER'
}

export type ConversationUpdated = {
  __typename?: 'ConversationUpdated';
  unreadMessageCount: Scalars['Int'];
  countOfMessagesUpdated: Scalars['Int'];
  conversationId: Scalars['Int'];
  relatedConversationId?: Maybe<Scalars['Int']>;
};

export type ConversationUser = {
  __typename?: 'ConversationUser';
  conversationUserType: Scalars['String'];
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type ConversationUserWithStorefront = {
  __typename?: 'ConversationUserWithStorefront';
  conversationUserType: Scalars['String'];
  storefront?: Maybe<Storefront>;
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type Create1to1ConversationInput = {
  conversationType: ConversationType;
  conversationOtherUserId: Scalars['ID'];
  withinContext: MessageWithinContext;
  newMessage?: Maybe<Scalars['String']>;
  relatedMessage?: Maybe<Scalars['String']>;
};

export type CreateAndReviseOrderInput = {
  buyerId: Scalars['ID'];
  amount: Scalars['Float'];
  selectedDate: Scalars['Date'];
  /** Hours, Products or none */
  units?: Maybe<Scalars['Int']>;
  additionalCost?: Maybe<Scalars['Float']>;
  pricingDetail?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  title: Scalars['String'];
  icon: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
};

export type CreateChatRoomInput = {
  firstUserType?: Maybe<UserType>;
  secondUserType?: Maybe<UserType>;
  withinContext?: Maybe<MessageWithinContext>;
  secondUserId: Scalars['ID'];
};

export type CreateCommentInput = {
  storefrontId: Scalars['Int'];
  body: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
};

/** https://projects.invisionapp.com/d/main#/console/16585436/348681909/preview#project_console */
export type CreateOrderInput = {
  storefrontId: Scalars['Int'];
  preferredDateStart: Scalars['Date'];
  preferredDateEnd: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CreateOrderInputV2 = {
  storefrontId: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  preferredDateStart: Scalars['Date'];
  preferredDateEnd: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CreateReviewInput = {
  storefrontId: Scalars['Int'];
  body: Scalars['String'];
  rating: Scalars['Float'];
  orderId?: Maybe<Scalars['Int']>;
};

export type CreateReviewResponseInput = {
  commentId: Scalars['Int'];
  sellerResponse: Scalars['String'];
};

export type CreateStorefrontInput = {
  categoryId?: Maybe<Scalars['Int']>;
  priceType: PriceType;
  businessName: Scalars['String'];
  tagline: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Float'];
  priceHigh?: Maybe<Scalars['Float']>;
  city: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  detail?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  geofenceRadius?: Maybe<Scalars['Float']>;
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
};

export type CreateStorefrontInputV2 = {
  uploadPhotos?: Maybe<Array<Scalars['Upload']>>;
  /** Uri or file name */
  primaryImage?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  instaUserId?: Maybe<Scalars['String']>;
  instaAccessToken?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  priceType: PriceType;
  businessName: Scalars['String'];
  tagline: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Float'];
  priceHigh?: Maybe<Scalars['Float']>;
  city: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  detail?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  geofenceRadius?: Maybe<Scalars['Float']>;
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
};

export type CreateTagInput = {
  tag: Scalars['String'];
  category?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: UserType;
  zip: Scalars['String'];
  city: Scalars['String'];
  streetAddress: Scalars['String'];
  welcomeEmail?: Maybe<Scalars['Boolean']>;
};

export type CreditCardInfo = {
  __typename?: 'CreditCardInfo';
  brand: Scalars['String'];
  number: Scalars['String'];
  expiry: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentStorefront = {
  __typename?: 'CurrentStorefront';
  statistics: StorefrontStatistics;
  balance: StorefrontBalance;
  hasPaymentSetup: Scalars['Boolean'];
  stripeStatus: StripeStatus;
  id: Scalars['Int'];
  owner: User;
  category?: Maybe<Category>;
  priceType: PriceType;
  status: StorefrontStatus;
  businessName: Scalars['String'];
  tagline: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Float'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  photos: StorefrontStorageInfo;
  dynamicLink?: Maybe<Scalars['String']>;
  priceHigh?: Maybe<Scalars['Float']>;
  detail?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  created_at: Scalars['Date'];
  outsideGeofenceRadius?: Maybe<Scalars['Boolean']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  instaUserId?: Maybe<Scalars['String']>;
  instaAccessToken?: Maybe<Scalars['String']>;
  geofenceRadius: Scalars['Float'];
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
  catalog?: Maybe<Array<Maybe<Catalog>>>;
  catalogSummary?: Maybe<Array<Maybe<CatalogSummary>>>;
  catalogSortOrder?: Maybe<Scalars['catalogSortOrderJSONB']>;
  visualTagsJSON?: Maybe<Scalars['visualTagsJSONB']>;
  approvalDate?: Maybe<Scalars['Date']>;
  adminCategoryJSON?: Maybe<Scalars['adminCategoryJSON']>;
  measuredStatistics?: Maybe<Scalars['measuredStatistics']>;
};


export type CurrentStorefrontDistanceArgs = {
  filter?: Maybe<DistanceStorefrontFilter>;
};


export type CurrentStorefrontMeasuredStatisticsArgs = {
  filter?: Maybe<StatisticsFilter>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  storefront?: Maybe<CurrentStorefront>;
  type: UserType;
  isRegistrationComplete?: Maybe<Scalars['Boolean']>;
  profileJSON: Scalars['profileJSON'];
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type DashboardStat = {
  __typename?: 'DashboardStat';
  title: Scalars['String'];
  icon: Scalars['String'];
  value: Scalars['Float'];
  type: DashboardStatType;
};

export enum DashboardStatType {
  Numeric = 'NUMERIC',
  Currency = 'CURRENCY'
}


export type DiscountCodeRemoved = {
  __typename?: 'DiscountCodeRemoved';
  removed: Scalars['Boolean'];
};

export enum DiscountType {
  Fixed = 'FIXED',
  Percent = 'PERCENT'
}

export type DistanceFilterInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type DistanceStorefrontFilter = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  geofence?: Maybe<Scalars['Boolean']>;
  geofenceRadius?: Maybe<Scalars['Float']>;
};

export enum FeeType {
  FlatBuyer = 'FLAT_BUYER',
  PercentBuyer = 'PERCENT_BUYER',
  FlatSeller = 'FLAT_SELLER',
  PercentSeller = 'PERCENT_SELLER',
  Subscription = 'SUBSCRIPTION'
}

export type FilterAdminConversationListInput = {
  conversationId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<UserType>;
  orderBy?: Maybe<AdminConversationOrderBy>;
  orderByOrder?: Maybe<AdminConversationOrderByOrder>;
  adminStatus?: Maybe<ConversationAdminStatus>;
};

export type FilterAdminDiscountCodeInput = {
  discountCodeId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isReferral?: Maybe<Scalars['Boolean']>;
};

export type FilterAdminOrderInput = {
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
};

export type FilterAdminStorefrontInput = {
  id?: Maybe<Scalars['Int']>;
  priceType?: Maybe<PriceType>;
  categoryId?: Maybe<Scalars['Int']>;
  status?: Maybe<StorefrontStatus>;
  date?: Maybe<Sorting>;
  hasPaymentSetup?: Maybe<Scalars['Boolean']>;
  Storefront?: Maybe<StorefrontIdAdminFilter>;
  businessName?: Maybe<Scalars['String']>;
  businessNameOrStorefrontOwnerName?: Maybe<Scalars['String']>;
};

export type FilterCatalogInput = {
  catalogId?: Maybe<Scalars['Int']>;
  storefrontId?: Maybe<Scalars['Int']>;
};

export type FilterChatRoomInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type FilterCommentInput = {
  id?: Maybe<Scalars['Int']>;
  storefrontId?: Maybe<Scalars['Int']>;
};

export type FilterConversationListInput = {
  conversationUserType: UserType;
  conversationId?: Maybe<Scalars['Int']>;
  otherUserId?: Maybe<Scalars['ID']>;
};

export type FilterNotificationCenterInput = {
  appviewtype?: Maybe<UserType>;
};

export type FilterOrderInput = {
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<FilterOrderStatus>;
};

export enum FilterOrderStatus {
  Requested = 'REQUESTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

export type FilterOrderV2Input = {
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<FilterOrderV2Status>;
};

export enum FilterOrderV2Status {
  Open = 'OPEN',
  Paid = 'PAID'
}

export type FilterOrderV3Input = {
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<FilterOrderV2Status>;
  isNotReviewed?: Maybe<Scalars['Boolean']>;
  dueOver?: Maybe<Scalars['Int']>;
  storefrontId?: Maybe<Scalars['Int']>;
};

export type FilterStorefrontInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
  favorite?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['Int']>;
  priceLow?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  search?: Maybe<Scalars['String']>;
  distance?: Maybe<DistanceStorefrontFilter>;
  fulfillmentMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<StorefrontSort>;
};

export type FilterUserAdminInput = {
  uid?: Maybe<Scalars['ID']>;
  role?: Maybe<UserType>;
  search?: Maybe<Scalars['String']>;
};

export type FilterUserInput = {
  uid?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
};


export enum FulfillmentMethod {
  Local = 'LOCAL',
  Pickup = 'PICKUP',
  Shipping = 'SHIPPING',
  Virtual = 'VIRTUAL',
  Onsite = 'ONSITE'
}


/**
 * input CreateOrderInputV3 {
 * storefrontId: Int!
 * latitude: Float
 * longitude: Float
 * preferredDateStart: Date!
 * preferredDateEnd: Date!
 * note: String
 * amount: Float!
 * marketplaceCommission: Float!
 * buyerPaysMarketplaceCommission: Boolean!
 * paymentProcessingFee: Float!
 * }
 */
export type GetInTouchInput = {
  storefrontId: Scalars['Int'];
  note: Scalars['String'];
  deliveryMethod?: Maybe<Scalars['String']>;
  timeframe?: Maybe<Scalars['String']>;
  isQuickShop?: Maybe<Scalars['Boolean']>;
};

export enum GetInTouchVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3'
}

export type GlobalNotificationWidget = {
  __typename?: 'GlobalNotificationWidget';
  notificationId: Scalars['Int'];
  created_at: Scalars['Date'];
  type: NotificationType;
  userType?: Maybe<UserType>;
  payload?: Maybe<Scalars['payloadJSON']>;
  expiry?: Maybe<Scalars['Date']>;
};

export enum GroupNotificationFilter {
  All = 'ALL',
  AllBuyers = 'ALL_BUYERS',
  AllSellers = 'ALL_SELLERS'
}

export type HasConversationWithAdmin = {
  __typename?: 'HasConversationWithAdmin';
  conversationId?: Maybe<Scalars['Int']>;
};

export type InitiateOrderInput = {
  serviceName: Scalars['String'];
  buyerId: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  additionalCost?: Maybe<Scalars['Float']>;
  pricingDetail?: Maybe<Scalars['String']>;
  isDeposit?: Maybe<Scalars['Boolean']>;
  selectedDate: Scalars['Date'];
  marketplaceCommission?: Maybe<Scalars['Float']>;
  buyerPaysMarketplaceCommission?: Maybe<Scalars['Boolean']>;
  paymentProcessingFee?: Maybe<Scalars['Float']>;
  totalToBePaid?: Maybe<Scalars['Float']>;
  salesTax?: Maybe<Scalars['Float']>;
};

export type InstagramPhoto = {
  __typename?: 'InstagramPhoto';
  id: Scalars['String'];
  mediaUrl: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
};

export type IsBlocked = {
  __typename?: 'IsBlocked';
  isBlocked: Scalars['Boolean'];
  byUserId?: Maybe<Scalars['ID']>;
};


export type Media = {
  __typename?: 'Media';
  mediaId: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  entityType: MediaEntityType;
  storefrontId: Scalars['Int'];
  catalogId?: Maybe<Scalars['Int']>;
  provider: Scalars['String'];
  providerBucket: Scalars['String'];
  contentType: Scalars['String'];
  mediaURL: Scalars['String'];
  cloudfrontURL?: Maybe<Scalars['String']>;
  lightboxZoomURL?: Maybe<Scalars['String']>;
  heroURL?: Maybe<Scalars['String']>;
  storefrontCardURL?: Maybe<Scalars['String']>;
  catalogCardURL?: Maybe<Scalars['String']>;
  catalogThumbnailURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  status: MediaStatus;
  caption?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  accessToken?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export enum MediaEntityType {
  Storefront = 'STOREFRONT',
  Catalog = 'CATALOG'
}


export enum MediaStatus {
  AwaitingUpload = 'AWAITING_UPLOAD',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED',
  NotApproved = 'NOT_APPROVED'
}


export enum MessageWithinContext {
  GetInTouch = 'GET_IN_TOUCH',
  QuickshopRequest = 'QUICKSHOP_REQUEST',
  NewMessage = 'NEW_MESSAGE',
  Userid = 'USERID',
  Csoutreach = 'CSOUTREACH'
}


export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated for old version - use v2 for new one */
  createStorefront?: Maybe<Storefront>;
  /** @deprecated for old version - use v2 for new one */
  updateStorefront?: Maybe<Storefront>;
  createStorefrontV2?: Maybe<Storefront>;
  updateStorefrontV2?: Maybe<Storefront>;
  updateStorefrontDynamicLink?: Maybe<Storefront>;
  requestStorefront?: Maybe<Storefront>;
  pauseStorefront?: Maybe<Storefront>;
  pauseStorefrontwithDate?: Maybe<Storefront>;
  unpauseStorefront?: Maybe<Storefront>;
  approveStorefront?: Maybe<Storefront>;
  rejectStorefront?: Maybe<Storefront>;
  deactivateStorefront?: Maybe<Storefront>;
  activateStorefront?: Maybe<Storefront>;
  deleteStorefront?: Maybe<Scalars['Boolean']>;
  cancelStorefront?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  /** Web */
  registerUserContact: RegisteredUserContact;
  registeredUserUpdate: RegistrationStatus;
  registerSharedLead: RegistrationStatus;
  upsertStorefrontCatalogItem?: Maybe<Catalog>;
  updateStorefrontCatalogSortOrder?: Maybe<Scalars['Boolean']>;
  deleteStorefrontMediaItem?: Maybe<Scalars['Boolean']>;
  reportStorefront: Scalars['Boolean'];
  createCategory?: Maybe<Category>;
  updateCategory?: Maybe<Category>;
  deleteCategory: Scalars['Boolean'];
  createPaymentMethod?: Maybe<Scalars['Boolean']>;
  createStripeAccount?: Maybe<Scalars['Boolean']>;
  removePaymentMethod: Scalars['Boolean'];
  updatePaymentMethod?: Maybe<Scalars['Boolean']>;
  /**
   * ##### ORDER FLOW V1:
   *  Buyer
   */
  createOrderV2?: Maybe<Order>;
  /** Seller */
  reviseOrder?: Maybe<Order>;
  /** Seller - previous two steps merged together */
  createAndReviseOrder?: Maybe<Order>;
  /** Buyer */
  updateOrder?: Maybe<Order>;
  /** Buyer or Seller */
  cancelOrder?: Maybe<Order>;
  /** Buyer & Seller */
  acceptOrder?: Maybe<Order>;
  /** Seller */
  completeOrder?: Maybe<Order>;
  /** Buyer */
  payOrder?: Maybe<Order>;
  applyDiscountCodetoInvoice?: Maybe<OrderDiscountCodeRemoved>;
  /**
   * ##### ORDER FLOW V2:
   *  sends a message to seller and returns a chatroom id
   */
  getInTouchV3: ConversationCreated;
  getInTouch: Scalars['String'];
  /** creates order entity in database (ready to be paid) */
  initiateOrder?: Maybe<Order>;
  /** Seller */
  uploadImages?: Maybe<Scalars['Boolean']>;
  createComment?: Maybe<Comment>;
  createReview?: Maybe<Comment>;
  createReviewResponse?: Maybe<Comment>;
  updateComment?: Maybe<Comment>;
  deleteComment: Scalars['Boolean'];
  reportComment: Scalars['Boolean'];
  addReaction: Scalars['Boolean'];
  removeReaction: Scalars['Boolean'];
  /** legacy twilio Chat */
  createChatRoomV3: Scalars['String'];
  /** Messaging related */
  blockUser?: Maybe<UserBlacklist>;
  unblockUser?: Maybe<Scalars['Boolean']>;
  /** uses twilio Conversations */
  createConversation: ConversationCreated;
  readConversationMessage: ConversationUpdated;
  /** @deprecated use createUser and updateUser in new versions */
  registerAddress?: Maybe<User>;
  addFavorite?: Maybe<Scalars['Boolean']>;
  /**
   * While user is browsing anonymously
   * gathers the favorites locally and then
   * flushes them after signing up using this mutation:
   */
  flushFavorites?: Maybe<Scalars['Boolean']>;
  removeFavorite?: Maybe<Scalars['Boolean']>;
  removeAllFavorites?: Maybe<Scalars['Boolean']>;
  changeMainPhotoV2: Scalars['Boolean'];
  updateSettings?: Maybe<Settings>;
  /** @deprecated for old version - use v2 for new one */
  createUser: CurrentUser;
  /** @deprecated for old version - use v2 for new one */
  updateUser: CurrentUser;
  createUserV2: CurrentUser;
  updateUserV2: CurrentUser;
  removeCurrentUser: Scalars['Boolean'];
  updateUserType: Scalars['Boolean'];
  updateUserTypeV2: CurrentUser;
  updateUserCurrentAppViewType: Scalars['Boolean'];
  notificationReadReceipt: Scalars['Boolean'];
  createMyReferralCode?: Maybe<Scalars['String']>;
  updateUserRegistrationStatus: RegistrationStatus;
  /** Admin: */
  notify: Scalars['Boolean'];
  adminCreateNotificationCenter?: Maybe<AdminNotificationCenter>;
  adminCreateDiscountCode?: Maybe<AdminDiscountCode>;
  adminDiscountCodeUpdate?: Maybe<AdminDiscountCode>;
  adminCreateTag?: Maybe<AdminTag>;
  adminUpdateTag?: Maybe<AdminTag>;
  adminDeleteTag: Scalars['Boolean'];
  adminUpdateStorefrontTag?: Maybe<Array<Maybe<AdminStorefrontTag>>>;
  adminUpdateStorefrontPrimaryImage: Storefront;
  adminCreateUser: CurrentUser;
  adminDeleteUser?: Maybe<AdminDeleteUser>;
  adminConversationUpdateStatus?: Maybe<AdminConversationList>;
  adminResyncTwilioMessages: Scalars['Boolean'];
};


export type MutationCreateStorefrontArgs = {
  storefront: CreateStorefrontInput;
};


export type MutationUpdateStorefrontArgs = {
  storefront: UpdateStorefrontInput;
};


export type MutationCreateStorefrontV2Args = {
  storefront: CreateStorefrontInputV2;
};


export type MutationUpdateStorefrontV2Args = {
  storefront: UpdateStorefrontInputV2;
};


export type MutationUpdateStorefrontDynamicLinkArgs = {
  storefront?: Maybe<Scalars['String']>;
};


export type MutationRequestStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationPauseStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationPauseStorefrontwithDateArgs = {
  storefront: PauseStorefrontwithDate;
};


export type MutationUnpauseStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationApproveStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationRejectStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationDeactivateStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationActivateStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationCancelStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  uid: Scalars['String'];
};


export type MutationRegisterUserContactArgs = {
  register: RegisterUserContactInput;
};


export type MutationRegisteredUserUpdateArgs = {
  user: UpdateUserRegistrationInput;
};


export type MutationRegisterSharedLeadArgs = {
  contactId: Scalars['Int'];
};


export type MutationUpsertStorefrontCatalogItemArgs = {
  catalog: StorefrontCatalogInput;
};


export type MutationUpdateStorefrontCatalogSortOrderArgs = {
  catalogSortOrder: Scalars['catalogSortOrderJSON'];
};


export type MutationDeleteStorefrontMediaItemArgs = {
  mediaId: Scalars['Int'];
  isCatalogMedia?: Maybe<Scalars['Boolean']>;
};


export type MutationReportStorefrontArgs = {
  id: Scalars['Int'];
};


export type MutationCreateCategoryArgs = {
  category: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  category: UpdateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationCreatePaymentMethodArgs = {
  cardToken: Scalars['String'];
};


export type MutationCreateStripeAccountArgs = {
  authorizationCode: Scalars['String'];
};


export type MutationUpdatePaymentMethodArgs = {
  cardToken: Scalars['String'];
};


export type MutationCreateOrderV2Args = {
  order: CreateOrderInputV2;
};


export type MutationReviseOrderArgs = {
  order: ReviseOrderInput;
  appusertype?: Maybe<UserType>;
};


export type MutationCreateAndReviseOrderArgs = {
  order: CreateAndReviseOrderInput;
};


export type MutationUpdateOrderArgs = {
  order: UpdateOrderInput;
};


export type MutationCancelOrderArgs = {
  orderId: Scalars['Int'];
  appusertype?: Maybe<UserType>;
};


export type MutationAcceptOrderArgs = {
  orderId: Scalars['Int'];
};


export type MutationCompleteOrderArgs = {
  order: CompleteOrderInput;
};


export type MutationPayOrderArgs = {
  order: PayOrderInput;
  appusertype?: Maybe<UserType>;
};


export type MutationApplyDiscountCodetoInvoiceArgs = {
  order: ApplyDiscountCodetoInvoiceInput;
  appusertype?: Maybe<UserType>;
  remove?: Maybe<Scalars['Boolean']>;
};


export type MutationGetInTouchV3Args = {
  order: GetInTouchInput;
  appusertype?: Maybe<UserType>;
  version?: Maybe<GetInTouchVersion>;
};


export type MutationGetInTouchArgs = {
  order: GetInTouchInput;
  appusertype?: Maybe<UserType>;
  version?: Maybe<GetInTouchVersion>;
};


export type MutationInitiateOrderArgs = {
  order: InitiateOrderInput;
};


export type MutationUploadImagesArgs = {
  input: UploadFilesInput;
};


export type MutationCreateCommentArgs = {
  comment: CreateCommentInput;
};


export type MutationCreateReviewArgs = {
  review: CreateReviewInput;
};


export type MutationCreateReviewResponseArgs = {
  reviewResponse: CreateReviewResponseInput;
};


export type MutationUpdateCommentArgs = {
  comment: UpdateCommentInput;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['Int'];
};


export type MutationReportCommentArgs = {
  id: Scalars['Int'];
};


export type MutationAddReactionArgs = {
  commentId: Scalars['Int'];
  type?: Maybe<ReactionType>;
};


export type MutationRemoveReactionArgs = {
  commentId: Scalars['Int'];
  type?: Maybe<ReactionType>;
};


export type MutationCreateChatRoomV3Args = {
  chatroom: CreateChatRoomInput;
  appusertype?: Maybe<UserType>;
};


export type MutationBlockUserArgs = {
  id: Scalars['ID'];
};


export type MutationUnblockUserArgs = {
  id: Scalars['ID'];
};


export type MutationCreateConversationArgs = {
  conversation: Create1to1ConversationInput;
  appusertype?: Maybe<UserType>;
};


export type MutationReadConversationMessageArgs = {
  conversation: ReadConversationMessageInput;
  appusertype: UserType;
};


export type MutationRegisterAddressArgs = {
  address: RegisterUserAddressInput;
};


export type MutationAddFavoriteArgs = {
  storefrontId: Scalars['Int'];
};


export type MutationFlushFavoritesArgs = {
  storefrontIds: Array<Scalars['Int']>;
};


export type MutationRemoveFavoriteArgs = {
  storefrontId: Scalars['Int'];
};


export type MutationChangeMainPhotoV2Args = {
  uid: Scalars['String'];
};


export type MutationUpdateSettingsArgs = {
  settings?: Maybe<UpdateSettingsInput>;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};


export type MutationCreateUserV2Args = {
  user: CreateUserInput;
};


export type MutationUpdateUserV2Args = {
  user: UpdateUserInput;
};


export type MutationUpdateUserTypeArgs = {
  user: UpdateUserTypeInput;
};


export type MutationUpdateUserTypeV2Args = {
  user: UpdateUserTypeInput;
};


export type MutationUpdateUserCurrentAppViewTypeArgs = {
  user: UpdateUserViewTypeInput;
};


export type MutationNotificationReadReceiptArgs = {
  notificationId: Scalars['Int'];
};


export type MutationUpdateUserRegistrationStatusArgs = {
  login: UpdateUserRegistrationStatusInput;
};


export type MutationNotifyArgs = {
  input: NotifyInput;
};


export type MutationAdminCreateNotificationCenterArgs = {
  notification: AdminNotificationInput;
};


export type MutationAdminCreateDiscountCodeArgs = {
  discountcode: AdminDiscountCodeInput;
};


export type MutationAdminDiscountCodeUpdateArgs = {
  discountcode: AdminDiscountCodeUpdateInput;
};


export type MutationAdminCreateTagArgs = {
  tag: CreateTagInput;
};


export type MutationAdminUpdateTagArgs = {
  tag: UpdateTagInput;
};


export type MutationAdminDeleteTagArgs = {
  tagId: Scalars['Int'];
};


export type MutationAdminUpdateStorefrontTagArgs = {
  tag: UpdateStorefrontTagInput;
};


export type MutationAdminUpdateStorefrontPrimaryImageArgs = {
  storefront: AdminStorefrontPrimaryImageInput;
};


export type MutationAdminCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationAdminDeleteUserArgs = {
  user: AdminDeleteUserInput;
};


export type MutationAdminConversationUpdateStatusArgs = {
  conversation: AdminConversationUpdateStatus;
};


export type MutationAdminResyncTwilioMessagesArgs = {
  twilioConversationSid: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['Int'];
  body: Scalars['String'];
  author: User;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  seenAt: Scalars['Date'];
};

export type NotesFilterInput = {
  id?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['ID']>;
};

export enum NotificationType {
  Notification = 'NOTIFICATION',
  Storefront = 'STOREFRONT',
  Category = 'CATEGORY',
  Tynrequest = 'TYNREQUEST',
  Tynreceived = 'TYNRECEIVED',
  Paymentrequest = 'PAYMENTREQUEST',
  Paymentreceived = 'PAYMENTRECEIVED',
  Ordercancelled = 'ORDERCANCELLED'
}

export type NotifyInput = {
  uids?: Maybe<Array<Scalars['String']>>;
  groupFilter?: Maybe<GroupNotificationFilter>;
  body: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Int'];
  buyer: User;
  storefront: Storefront;
  referenceNumber: Scalars['Int'];
  status?: Maybe<OrderStatus>;
  /** When buyer is available */
  preferredDateStart: Scalars['Date'];
  /** When buyer is available */
  preferredDateEnd: Scalars['Date'];
  /** When the order will happen */
  selectedDate?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['Float']>;
  /** Additional costs like shipping */
  additionalCost?: Maybe<Scalars['Float']>;
  /** Details about pricing */
  pricingDetail?: Maybe<Scalars['String']>;
  /** hours or products */
  units?: Maybe<Scalars['Int']>;
  /**
   * after completing order
   * - seller might have worked more than expected
   */
  extraUnits?: Maybe<Scalars['Float']>;
  /** hourly rate or productly rate */
  rate?: Maybe<Scalars['Float']>;
  /** when the buyer will be charged automatically */
  dueDate?: Maybe<Scalars['Date']>;
  /**
   * this is from old design - ignore that for now
   * @deprecated old design
   */
  serviceName?: Maybe<Scalars['String']>;
  /** when seller hits Complete Order */
  completedAt?: Maybe<Scalars['Date']>;
  /** when buyer's money are transferred */
  chargedAt?: Maybe<Scalars['Date']>;
  /** when seller revised the order */
  storefrontSignedAt?: Maybe<Scalars['Date']>;
  /** when buyer accepted the order */
  buyerSignedAt?: Maybe<Scalars['Date']>;
  /**
   * text that seller and buyer have exchanged
   * during the order initialization
   */
  notes: Array<Note>;
  /**
   * Calculated distance
   * between buyer and seller
   */
  distance: Scalars['Float'];
  /** When the order was firstly placed */
  created_at: Scalars['Date'];
  /** When buyer is outside of geofencing radius */
  outsideGeofenceRadius: Scalars['Boolean'];
  /** v1 or v2 or v3 based on flow in which order was created in (legacy) or marketplace commission */
  version?: Maybe<OrderVersion>;
  isDeposit?: Maybe<Scalars['Boolean']>;
  /** v3 with marketplaceCommission and paymentProcessingFee fields */
  marketplaceCommission?: Maybe<Scalars['Int']>;
  buyerPaysMarketplaceCommission: Scalars['Boolean'];
  paymentProcessingFee?: Maybe<Scalars['Int']>;
  salesTax?: Maybe<Scalars['Float']>;
  totalToBePaid?: Maybe<Scalars['Int']>;
  discountCode?: Maybe<OrderDiscountCode>;
  totalCharged?: Maybe<Scalars['Float']>;
  commentId?: Maybe<Scalars['Int']>;
};


export type OrderNotesArgs = {
  filter?: Maybe<NotesFilterInput>;
};


export type OrderDistanceArgs = {
  filter?: Maybe<DistanceFilterInput>;
};

export type OrderDiscountCode = {
  __typename?: 'OrderDiscountCode';
  discountCodeId: Scalars['Int'];
  code: Scalars['String'];
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  orderMinimumAmount: Scalars['Float'];
  orderDiscountAmount?: Maybe<Scalars['Float']>;
  isReferral?: Maybe<Scalars['Boolean']>;
  usageMessageJSON?: Maybe<Scalars['usageMessageJSON']>;
};

export type OrderDiscountCodeRemoved = {
  __typename?: 'OrderDiscountCodeRemoved';
  orderDiscount?: Maybe<OrderDiscountCode>;
  discountRemoved?: Maybe<DiscountCodeRemoved>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Requested = 'REQUESTED',
  HalfSigned = 'HALF_SIGNED',
  FullySigned = 'FULLY_SIGNED',
  InProgress = 'IN_PROGRESS',
  UnpayedCompleted = 'UNPAYED_COMPLETED',
  Completed = 'COMPLETED'
}

export enum OrderVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V4 = 'V4'
}

export type PaginatedAdminConversationList = {
  __typename?: 'PaginatedAdminConversationList';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminConversationList>;
};

export type PaginatedAdminCsConversationList = {
  __typename?: 'PaginatedAdminCSConversationList';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminCsConversationList>;
};

export type PaginatedAdminDiscountCode = {
  __typename?: 'PaginatedAdminDiscountCode';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminDiscountCode>;
};

export type PaginatedAdminOutReachListBuyer = {
  __typename?: 'PaginatedAdminOutReachListBuyer';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminOutReachListBuyer>;
};

export type PaginatedAdminOutReachListSeller = {
  __typename?: 'PaginatedAdminOutReachListSeller';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminOutReachListSeller>;
};

export type PaginatedAdminStorefront = {
  __typename?: 'PaginatedAdminStorefront';
  pagination?: Maybe<PaginationResult>;
  data: Array<AdminStorefront>;
};

export type PaginatedChatRoom = {
  __typename?: 'PaginatedChatRoom';
  pagination?: Maybe<PaginationResult>;
  data: Array<ChatRoom>;
};

export type PaginatedComment = {
  __typename?: 'PaginatedComment';
  pagination?: Maybe<PaginationResult>;
  data: Array<Comment>;
};

export type PaginatedConversationList = {
  __typename?: 'PaginatedConversationList';
  pagination?: Maybe<PaginationResult>;
  data: Array<ConversationList>;
};

export type PaginatedOrder = {
  __typename?: 'PaginatedOrder';
  pagination?: Maybe<PaginationResult>;
  data: Array<Order>;
};

export type PaginatedStorefront = {
  __typename?: 'PaginatedStorefront';
  pagination?: Maybe<PaginationResult>;
  data: Array<Storefront>;
};

export type PaginatedUserAdmin = {
  __typename?: 'PaginatedUserAdmin';
  pagination?: Maybe<PaginationResult>;
  data: Array<UserAdmin>;
};

export type PaginationInput = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  total: Scalars['Int'];
  perPage: Scalars['Int'];
  currentPage: Scalars['Int'];
  maxPage: Scalars['Int'];
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type PauseStorefrontwithDate = {
  id: Scalars['Int'];
  startPauseDate: Scalars['Date'];
  endPauseDate?: Maybe<Scalars['Date']>;
};


export type PayOrderInput = {
  id: Scalars['Int'];
  donation?: Maybe<Scalars['Float']>;
};

export enum PriceType {
  PerHour = 'PER_HOUR',
  PerProduct = 'PER_PRODUCT',
  Varies = 'VARIES'
}


export type Query = {
  __typename?: 'Query';
  me?: Maybe<CurrentUser>;
  storefront?: Maybe<PaginatedStorefront>;
  adminStorefront?: Maybe<PaginatedAdminStorefront>;
  comment?: Maybe<PaginatedComment>;
  category: Array<Category>;
  categoryTaxonomy: Array<Maybe<CategoryTaxonomy>>;
  storageInfo: StorageInfo;
  chatroom: PaginatedChatRoom;
  blacklist: Array<UserBlacklist>;
  users: Array<Maybe<User>>;
  usersAdmin?: Maybe<PaginatedUserAdmin>;
  /** #### ORDER FLOW V1: */
  order?: Maybe<PaginatedOrder>;
  /** #### ORDER FLOW V2: */
  orderV2?: Maybe<PaginatedOrder>;
  orderV3?: Maybe<PaginatedOrder>;
  adminOrder?: Maybe<PaginatedOrder>;
  toBePaidOrdersCount: Scalars['Int'];
  contact?: Maybe<Contact>;
  settings?: Maybe<Settings>;
  validate: Validation;
  /** Returns credit cards for currently logged in user */
  creditCard?: Maybe<Array<CreditCardInfo>>;
  adminDashboard: Array<DashboardStat>;
  isBlocked: IsBlocked;
  catalog?: Maybe<Array<Maybe<Catalog>>>;
  mynotificationcenter?: Maybe<Array<Maybe<UserNotificationCenter>>>;
  myConversationList?: Maybe<PaginatedConversationList>;
  adminGetCohort?: Maybe<Array<Maybe<Cohort>>>;
  adminDiscountCodeList?: Maybe<PaginatedAdminDiscountCode>;
  adminTagList?: Maybe<Array<Maybe<AdminTagSummary>>>;
  adminTagDetail?: Maybe<AdminTag>;
  adminStripeStatus?: Maybe<StripeStatus>;
  adminCategoryList?: Maybe<Array<Maybe<AdminCategory>>>;
  adminConversationList?: Maybe<PaginatedAdminConversationList>;
  adminConversationDetail?: Maybe<AdminConversationDetail>;
  adminCSConversationList?: Maybe<PaginatedAdminCsConversationList>;
  adminOutReachListBuyer?: Maybe<PaginatedAdminOutReachListBuyer>;
  adminOutReachListSeller?: Maybe<PaginatedAdminOutReachListSeller>;
  hasProxyConversation?: Maybe<HasConversationWithAdmin>;
  globalNotificationWidget?: Maybe<GlobalNotificationWidget>;
  userRegistrationStatus: UserRegistrationStatus;
  sitemap: SiteMap;
};


export type QueryStorefrontArgs = {
  filter?: Maybe<FilterStorefrontInput>;
  pagination?: Maybe<PaginationInput>;
  appusertype?: Maybe<UserType>;
};


export type QueryAdminStorefrontArgs = {
  filter?: Maybe<FilterAdminStorefrontInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryCommentArgs = {
  filter?: Maybe<FilterCommentInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryChatroomArgs = {
  filter?: Maybe<FilterChatRoomInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryUsersArgs = {
  filter: FilterUserInput;
};


export type QueryUsersAdminArgs = {
  filter?: Maybe<FilterUserAdminInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryOrderArgs = {
  filter?: Maybe<FilterOrderInput>;
  pagination?: Maybe<PaginationInput>;
  appusertype?: Maybe<UserType>;
  supportedOrderVersion?: Maybe<OrderVersion>;
};


export type QueryOrderV2Args = {
  filter?: Maybe<FilterOrderV3Input>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryOrderV3Args = {
  filter?: Maybe<FilterOrderV3Input>;
  pagination?: Maybe<PaginationInput>;
  appusertype?: Maybe<UserType>;
  supportedOrderVersion?: Maybe<OrderVersion>;
};


export type QueryAdminOrderArgs = {
  filter?: Maybe<FilterAdminOrderInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryIsBlockedArgs = {
  roomId: Scalars['Int'];
};


export type QueryCatalogArgs = {
  filter?: Maybe<FilterCatalogInput>;
};


export type QueryMynotificationcenterArgs = {
  filter?: Maybe<FilterNotificationCenterInput>;
};


export type QueryMyConversationListArgs = {
  filter: FilterConversationListInput;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdminDiscountCodeListArgs = {
  filter?: Maybe<FilterAdminDiscountCodeInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdminTagDetailArgs = {
  tagId: Scalars['Int'];
};


export type QueryAdminStripeStatusArgs = {
  storefrontId: Scalars['Int'];
};


export type QueryAdminConversationListArgs = {
  filter?: Maybe<FilterAdminConversationListInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdminConversationDetailArgs = {
  conversation: Scalars['Int'];
};


export type QueryAdminCsConversationListArgs = {
  filter?: Maybe<FilterAdminConversationListInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdminOutReachListBuyerArgs = {
  filter?: Maybe<FilterAdminConversationListInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdminOutReachListSellerArgs = {
  filter?: Maybe<FilterAdminConversationListInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryHasProxyConversationArgs = {
  userId: Scalars['ID'];
  userType: UserType;
};


export type QueryUserRegistrationStatusArgs = {
  email: Scalars['String'];
};

export type ReactionsFilter = {
  type?: Maybe<ReactionType>;
};

export enum ReactionType {
  Helpful = 'HELPFUL'
}

export type ReadConversationMessageInput = {
  conversationId: Scalars['Int'];
  updateAll: Scalars['Boolean'];
  twilioMessageSid?: Maybe<Scalars['String']>;
  isUnread?: Maybe<Scalars['Boolean']>;
};

export type RegisteredUserContact = {
  __typename?: 'RegisteredUserContact';
  uid?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
};

export type RegisterUserAddressInput = {
  zip: Scalars['String'];
  city: Scalars['String'];
  streetAddress: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type RegisterUserContactInput = {
  user: RegisterUserInput;
  contact: GetInTouchInput;
};

export type RegisterUserCoordsInput = {
  lng?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  zip: Scalars['String'];
  utm?: Maybe<UtmJsonInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  coords?: Maybe<RegisterUserCoordsInput>;
  attribution?: Maybe<Scalars['attributionJSON']>;
};

export type RegistrationStatus = {
  __typename?: 'RegistrationStatus';
  success: Scalars['Boolean'];
};


/**
 * https://projects.invisionapp.com/d/main#/console/16585436/348685281/preview#project_console
 * https://projects.invisionapp.com/d/main#/console/16585436/350051814/preview#project_console
 */
export type ReviseOrderInput = {
  id: Scalars['Int'];
  amount: Scalars['Float'];
  selectedDate: Scalars['Date'];
  /** Hours, Products or none */
  units?: Maybe<Scalars['Int']>;
  additionalCost?: Maybe<Scalars['Float']>;
  pricingDetail?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  geolocation: Scalars['Boolean'];
  thankYouNotes: Scalars['Boolean'];
  messaging: SettingsMessaging;
  notifications: SettingsNotifications;
};

export type SettingsMessaging = {
  __typename?: 'SettingsMessaging';
  storefrontOwners: Scalars['Boolean'];
  otherUsers: Scalars['Boolean'];
};

export type SettingsNotifications = {
  __typename?: 'SettingsNotifications';
  emailNotifications: Scalars['Boolean'];
  pushNotifications: Scalars['Boolean'];
};

export type SiteMap = {
  __typename?: 'SiteMap';
  browse?: Maybe<Scalars['SiteMapBrowseType']>;
  shop?: Maybe<Scalars['SiteMapShopType']>;
  category?: Maybe<Scalars['SiteMapCategoryType']>;
  catalog?: Maybe<Scalars['SiteMapCatalogType']>;
};





export enum Sorting {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StatisticsFilter = {
  storefrontId: Scalars['Int'];
  statisticsOptions?: Maybe<Scalars['String']>;
};

export type StorageFileInfo = {
  __typename?: 'StorageFileInfo';
  name: Scalars['String'];
  uri: Scalars['String'];
  size: Scalars['Float'];
  contentType: Scalars['String'];
};

export type StorageInfo = {
  __typename?: 'StorageInfo';
  storefront?: Maybe<StorefrontStorageInfo>;
  storefrontV2?: Maybe<StorefrontStorageInfo>;
  user: Array<StorageFileInfo>;
};

export type Storefront = {
  __typename?: 'Storefront';
  id: Scalars['Int'];
  owner: User;
  category?: Maybe<Category>;
  priceType: PriceType;
  status: StorefrontStatus;
  businessName: Scalars['String'];
  tagline: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Float'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  photos: StorefrontStorageInfo;
  dynamicLink?: Maybe<Scalars['String']>;
  priceHigh?: Maybe<Scalars['Float']>;
  detail?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  created_at: Scalars['Date'];
  outsideGeofenceRadius?: Maybe<Scalars['Boolean']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  instaUserId?: Maybe<Scalars['String']>;
  instaAccessToken?: Maybe<Scalars['String']>;
  geofenceRadius: Scalars['Float'];
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
  catalog?: Maybe<Array<Maybe<Catalog>>>;
  catalogSummary?: Maybe<Array<Maybe<CatalogSummary>>>;
  catalogSortOrder?: Maybe<Scalars['catalogSortOrderJSONB']>;
  visualTagsJSON?: Maybe<Scalars['visualTagsJSONB']>;
  approvalDate?: Maybe<Scalars['Date']>;
  adminCategoryJSON?: Maybe<Scalars['adminCategoryJSON']>;
  measuredStatistics?: Maybe<Scalars['measuredStatistics']>;
};


export type StorefrontDistanceArgs = {
  filter?: Maybe<DistanceStorefrontFilter>;
};


export type StorefrontMeasuredStatisticsArgs = {
  filter?: Maybe<StatisticsFilter>;
};

export type StorefrontBalance = {
  __typename?: 'StorefrontBalance';
  available: Scalars['Float'];
  history: Array<StorefrontTransaction>;
};

/**
 * ## We have a simplified input because we're using an upsert that allows for
 * delete. We handle the validation in the resolver instead
 */
export type StorefrontCatalogInput = {
  catalogId?: Maybe<Scalars['Int']>;
  storefrontId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  priceType?: Maybe<CatalogPriceType>;
  price?: Maybe<Scalars['Float']>;
  pricingDescription?: Maybe<Scalars['String']>;
  status: CatalogStatus;
  fulfillmentMethodJSON?: Maybe<Scalars['fulfillmentMethodJSON']>;
  mediaSortOrder?: Maybe<Scalars['mediaSortOrderJSON']>;
  allowSelectQuantity?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<Maybe<CatalogMediaInput>>>;
};

export type StorefrontIdAdminFilter = {
  id?: Maybe<Scalars['Int']>;
};

export enum StorefrontSort {
  Relevance = 'RELEVANCE',
  Proximity = 'PROXIMITY',
  Newest = 'NEWEST',
  Highest = 'HIGHEST',
  Lowest = 'LOWEST'
}

export type StorefrontStatistics = {
  __typename?: 'StorefrontStatistics';
  profit: Scalars['Float'];
  completedOrders: Scalars['Int'];
  thankYouNotes: Scalars['Int'];
};

export enum StorefrontStatus {
  Deactivated = 'DEACTIVATED',
  Blocked = 'BLOCKED',
  NotYetRequested = 'NOT_YET_REQUESTED',
  NoPayment = 'NO_PAYMENT',
  Rejected = 'REJECTED',
  Paused = 'PAUSED',
  New = 'NEW',
  Requested = 'REQUESTED',
  Active = 'ACTIVE',
  ActiveNoPayment = 'ACTIVE_NO_PAYMENT'
}

export type StorefrontStorageInfo = {
  __typename?: 'StorefrontStorageInfo';
  main?: Maybe<StorageFileInfo>;
  others?: Maybe<Array<Maybe<StorageFileInfo>>>;
};

export type StorefrontTransaction = {
  __typename?: 'StorefrontTransaction';
  amount: Scalars['Float'];
  availableOn: Scalars['Date'];
  createdOn: Scalars['Date'];
  description: Scalars['String'];
  fee: Scalars['Float'];
  status: Scalars['String'];
  type: Scalars['String'];
};

export type StripeStatus = {
  __typename?: 'StripeStatus';
  charges_enabled?: Maybe<Scalars['Boolean']>;
  payouts_enabled?: Maybe<Scalars['Boolean']>;
};


export type UpdateCategoryInput = {
  id: Scalars['Int'];
  icon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  rank: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
};

export type UpdateCommentInput = {
  id: Scalars['Int'];
  storefrontId?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type UpdateOrderInput = {
  id: Scalars['Int'];
  amount?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateSettingsInput = {
  geolocation?: Maybe<Scalars['Boolean']>;
  thankYouNotes?: Maybe<Scalars['Boolean']>;
  storefrontOwners?: Maybe<Scalars['Boolean']>;
  otherUsers?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
};

export type UpdateStorefrontInput = {
  id: Scalars['Int'];
  categoryId?: Maybe<Scalars['Int']>;
  priceType?: Maybe<PriceType>;
  status?: Maybe<StorefrontStatus>;
  businessName?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  priceLow?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  geofenceRadius?: Maybe<Scalars['Float']>;
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
  visualTagsJSON?: Maybe<Scalars['visualTagsJSONB']>;
  adminCategoryJSON?: Maybe<Scalars['adminCategoryJSON']>;
  adminResetInsta?: Maybe<Scalars['Boolean']>;
};

export type UpdateStorefrontInputV2 = {
  uploadPhotos?: Maybe<Array<Scalars['Upload']>>;
  /** Uris or file names */
  deletePhotos?: Maybe<Array<Scalars['String']>>;
  /** Uri or file name */
  primaryImage?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  instaUserId?: Maybe<Scalars['String']>;
  instaAccessToken?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  categoryId?: Maybe<Scalars['Int']>;
  priceType?: Maybe<PriceType>;
  status?: Maybe<StorefrontStatus>;
  businessName?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  priceLow?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  geofenceRadius?: Maybe<Scalars['Float']>;
  fulfillmentJSON?: Maybe<Scalars['fulfillmentJSON']>;
  categoryJSON?: Maybe<Scalars['categoryJSON']>;
  visualTagsJSON?: Maybe<Scalars['visualTagsJSONB']>;
  adminCategoryJSON?: Maybe<Scalars['adminCategoryJSON']>;
  adminResetInsta?: Maybe<Scalars['Boolean']>;
};

export type UpdateStorefrontTagInput = {
  storefrontId: Scalars['Int'];
  deleteTag?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addTag?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateTagInput = {
  tagId: Scalars['Int'];
  deleteStorefront?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addStorefront?: Maybe<Array<Maybe<Scalars['Int']>>>;
  deleteCategory?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addCategory?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  welcomeEmail?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserRegistrationInput = {
  password: Scalars['String'];
};

export type UpdateUserRegistrationStatusInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  optional?: Maybe<Scalars['String']>;
};

export type UpdateUserTypeInput = {
  uid: Scalars['ID'];
  type: UserType;
};

export type UpdateUserViewTypeInput = {
  uid: Scalars['ID'];
  currentappviewtype: UserType;
};


export type UploadFile = {
  base64: Scalars['String'];
  uid: Scalars['String'];
  mime: Scalars['String'];
};

export type UploadFilesInput = {
  files: Array<UploadFile>;
};


export type User = {
  __typename?: 'User';
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type UserAdmin = {
  __typename?: 'UserAdmin';
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  created_at: Scalars['Date'];
};

export type UserBlacklist = {
  __typename?: 'UserBlacklist';
  id: Scalars['Int'];
  user: User;
  blocked: User;
};

export type UserNotificationCenter = {
  __typename?: 'UserNotificationCenter';
  notificationId: Scalars['Int'];
  created_at: Scalars['Date'];
  type: NotificationType;
  userType?: Maybe<UserType>;
  payload?: Maybe<Scalars['payloadJSON']>;
  isRead?: Maybe<Scalars['Boolean']>;
  readAt?: Maybe<Scalars['Date']>;
  isPinned?: Maybe<Scalars['Boolean']>;
};

export type UserRegistrationStatus = {
  __typename?: 'UserRegistrationStatus';
  validation: Scalars['Int'];
  optional?: Maybe<Scalars['String']>;
};

export enum UserType {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Admin = 'ADMIN',
  Ambassador = 'AMBASSADOR'
}

export type UserWithStorefront = {
  __typename?: 'UserWithStorefront';
  storefront?: Maybe<Storefront>;
  uid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  favorites?: Maybe<Array<Maybe<Storefront>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  settings: Settings;
  created_at: Scalars['Date'];
  isAnonymous: Scalars['Boolean'];
  currentappviewtype?: Maybe<UserType>;
  referralCode?: Maybe<Scalars['String']>;
};

export type UtmJsonInput = {
  utm_campaign?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
};

export type Validation = {
  __typename?: 'Validation';
  businessName: Scalars['Boolean'];
  address: Scalars['Boolean'];
  creditCard: Scalars['Boolean'];
};


export type ValidationBusinessNameArgs = {
  businessName: Scalars['String'];
};


export type ValidationAddressArgs = {
  address: ValidationAddress;
};

export type ValidationAddress = {
  zipCode: Scalars['String'];
  address: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};


export type DeleteUserAdminMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DeleteUserAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type ApproveStorefrontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApproveStorefrontMutation = (
  { __typename?: 'Mutation' }
  & { approveStorefront?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
  )> }
);

export type ActivateStorefrontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActivateStorefrontMutation = (
  { __typename?: 'Mutation' }
  & { activateStorefront?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
  )> }
);

export type DeactivateStorefrontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeactivateStorefrontMutation = (
  { __typename?: 'Mutation' }
  & { deactivateStorefront?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
  )> }
);

export type DeleteStorefrontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStorefrontMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStorefront'>
);

export type RejectStorefrontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RejectStorefrontMutation = (
  { __typename?: 'Mutation' }
  & { rejectStorefront?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
  )> }
);

export type SendNotificationMutationVariables = Exact<{
  input: NotifyInput;
}>;


export type SendNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'notify'>
);

export type UpdateStorefrontMutationVariables = Exact<{
  storefront: UpdateStorefrontInput;
}>;


export type UpdateStorefrontMutation = (
  { __typename?: 'Mutation' }
  & { updateStorefront?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'visualTagsJSON'>
  )> }
);

export type UpsertStorefrontCatalogItemMutationVariables = Exact<{
  catalog: StorefrontCatalogInput;
}>;


export type UpsertStorefrontCatalogItemMutation = (
  { __typename?: 'Mutation' }
  & { upsertStorefrontCatalogItem?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'catalogId' | 'status' | 'mediaSortOrder'>
  )> }
);

export type AdminCreateNotificationCenterMutationVariables = Exact<{
  notification: AdminNotificationInput;
}>;


export type AdminCreateNotificationCenterMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateNotificationCenter?: Maybe<(
    { __typename?: 'AdminNotificationCenter' }
    & Pick<AdminNotificationCenter, 'notificationId' | 'created_at' | 'updated_at' | 'type' | 'userType' | 'payload' | 'expiry' | 'cohort' | 'deletedAt' | 'countOfUsers'>
  )> }
);

export type CreateDiscountCodeMutationVariables = Exact<{
  discountCode: AdminDiscountCodeInput;
}>;


export type CreateDiscountCodeMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateDiscountCode?: Maybe<(
    { __typename?: 'AdminDiscountCode' }
    & Pick<AdminDiscountCode, 'discountCodeId' | 'code' | 'prefix' | 'discountType' | 'activeFrom' | 'activeTo' | 'orderMinimumAmount' | 'restrictUseJSON' | 'adminNotes' | 'maximumDiscountAmount' | 'isReferral'>
  )> }
);

export type AdminCreateTagMutationVariables = Exact<{
  tag: CreateTagInput;
}>;


export type AdminCreateTagMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateTag?: Maybe<(
    { __typename?: 'AdminTag' }
    & Pick<AdminTag, 'tagId' | 'tag'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagCategory' }
      & Pick<AdminTagCategory, 'categoryId' | 'categoryTitle'>
    )>>>, storefronts?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagStorefront' }
      & Pick<AdminTagStorefront, 'storefrontId' | 'businessName' | 'isAutoTag'>
    )>>> }
  )> }
);

export type AdminUpdateTagMutationVariables = Exact<{
  tag: UpdateTagInput;
}>;


export type AdminUpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateTag?: Maybe<(
    { __typename?: 'AdminTag' }
    & Pick<AdminTag, 'tagId' | 'tag'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagCategory' }
      & Pick<AdminTagCategory, 'categoryId' | 'categoryTitle'>
    )>>>, storefronts?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagStorefront' }
      & Pick<AdminTagStorefront, 'storefrontId' | 'businessName' | 'isAutoTag'>
    )>>> }
  )> }
);

export type AdminDeleteTagMutationVariables = Exact<{
  tagId: Scalars['Int'];
}>;


export type AdminDeleteTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteTag'>
);

export type AdminUpdateStorefrontTagMutationVariables = Exact<{
  tag: UpdateStorefrontTagInput;
}>;


export type AdminUpdateStorefrontTagMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateStorefrontTag?: Maybe<Array<Maybe<(
    { __typename?: 'AdminStorefrontTag' }
    & Pick<AdminStorefrontTag, 'tagId' | 'tag' | 'isAutoTag'>
  )>>> }
);

export type PauseStorefrontWithDateMutationVariables = Exact<{
  storefront: PauseStorefrontwithDate;
}>;


export type PauseStorefrontWithDateMutation = (
  { __typename?: 'Mutation' }
  & { pauseStorefrontwithDate?: Maybe<(
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
  )> }
);

export type UpdatePrimaryImageMutationVariables = Exact<{
  storefront: AdminStorefrontPrimaryImageInput;
}>;


export type UpdatePrimaryImageMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateStorefrontPrimaryImage: (
    { __typename?: 'Storefront' }
    & Pick<Storefront, 'id' | 'status'>
    & { photos: (
      { __typename?: 'StorefrontStorageInfo' }
      & { main?: Maybe<(
        { __typename?: 'StorageFileInfo' }
        & Pick<StorageFileInfo, 'uri'>
      )> }
    ) }
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteComment'>
);

export type AdminDiscountCodeUpdateMutationVariables = Exact<{
  discountCode: AdminDiscountCodeUpdateInput;
}>;


export type AdminDiscountCodeUpdateMutation = (
  { __typename?: 'Mutation' }
  & { adminDiscountCodeUpdate?: Maybe<(
    { __typename?: 'AdminDiscountCode' }
    & Pick<AdminDiscountCode, 'discountCodeId' | 'code' | 'prefix' | 'discountType' | 'activeFrom' | 'activeTo' | 'orderMinimumAmount' | 'adminNotes'>
  )> }
);

export type AdminDeleteUserMutationVariables = Exact<{
  user: AdminDeleteUserInput;
}>;


export type AdminDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteUser?: Maybe<(
    { __typename?: 'AdminDeleteUser' }
    & Pick<AdminDeleteUser, 'deletedUserId' | 'deletedStorefrontId'>
  )> }
);

export type CreateConversationMutationVariables = Exact<{
  conversation: Create1to1ConversationInput;
}>;


export type CreateConversationMutation = (
  { __typename?: 'Mutation' }
  & { createConversation: (
    { __typename?: 'ConversationCreated' }
    & Pick<ConversationCreated, 'conversationId' | 'relatedConversationId'>
  ) }
);

export type AdminConversationUpdateStatusMutationVariables = Exact<{
  conversation: AdminConversationUpdateStatus;
}>;


export type AdminConversationUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminConversationUpdateStatus?: Maybe<(
    { __typename?: 'AdminConversationList' }
    & Pick<AdminConversationList, 'conversationId' | 'adminStatus'>
  )> }
);

export type AdminResyncTwilioMessagesMutationVariables = Exact<{
  twilioConversationSid: Scalars['String'];
}>;


export type AdminResyncTwilioMessagesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminResyncTwilioMessages'>
);

export type AuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'uid' | 'type'>
  )> }
);

export type AuthUserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'uid'>
  )> }
);

export type GetDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardQuery = (
  { __typename?: 'Query' }
  & { adminDashboard: Array<(
    { __typename?: 'DashboardStat' }
    & Pick<DashboardStat, 'title' | 'icon' | 'value' | 'type'>
  )> }
);

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { category: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title' | 'uri' | 'isVirtual' | 'canStorefrontAssign'>
  )> }
);

export type GetUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  role?: Maybe<UserType>;
  uid?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { usersAdmin?: Maybe<(
    { __typename?: 'PaginatedUserAdmin' }
    & { data: Array<(
      { __typename?: 'UserAdmin' }
      & Pick<UserAdmin, 'uid' | 'zip' | 'city' | 'streetAddress' | 'photoURL' | 'displayName' | 'email' | 'type' | 'created_at'>
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )> }
  )> }
);

export type GetCatalogQueryVariables = Exact<{
  storefrontId: Scalars['Int'];
}>;


export type GetCatalogQuery = (
  { __typename?: 'Query' }
  & { catalog?: Maybe<Array<Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'catalogId' | 'storefrontId' | 'price' | 'created_at' | 'updated_at' | 'name' | 'description' | 'priceType' | 'pricingDescription' | 'status' | 'fulfillmentMethodJSON' | 'mediaSortOrder' | 'allowSelectQuantity'>
    & { media?: Maybe<Array<Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'cloudfrontURL' | 'contentType' | 'mediaId' | 'storefrontCardURL'>
    )>>> }
  )>>> }
);

export type GetAdminStorefrontsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  storefrontStatus?: Maybe<StorefrontStatus>;
  priceType?: Maybe<PriceType>;
  date?: Maybe<Sorting>;
  hasPaymentSetup?: Maybe<Scalars['Boolean']>;
  businessName?: Maybe<Scalars['String']>;
}>;


export type GetAdminStorefrontsQuery = (
  { __typename?: 'Query' }
  & { adminStorefront?: Maybe<(
    { __typename?: 'PaginatedAdminStorefront' }
    & { data: Array<(
      { __typename?: 'AdminStorefront' }
      & Pick<AdminStorefront, 'id' | 'businessName' | 'created_at' | 'status' | 'state' | 'priceLow' | 'priceHigh' | 'priceType' | 'hasPaymentSetup' | 'categoryJSON' | 'dynamicLink'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'uri' | 'title'>
      )>, owner: (
        { __typename?: 'User' }
        & Pick<User, 'uid' | 'displayName'>
      ), photos: (
        { __typename?: 'StorefrontStorageInfo' }
        & { main?: Maybe<(
          { __typename?: 'StorageFileInfo' }
          & Pick<StorageFileInfo, 'uri'>
        )> }
      ), catalogSummary?: Maybe<Array<Maybe<(
        { __typename?: 'CatalogSummary' }
        & Pick<CatalogSummary, 'count' | 'catalogStatus'>
      )>>> }
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )> }
  )> }
);

export type GetOrdersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { adminOrder?: Maybe<(
    { __typename?: 'PaginatedOrder' }
    & { pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )>, data: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'amount' | 'additionalCost' | 'status' | 'created_at' | 'completedAt' | 'dueDate'>
      & { buyer: (
        { __typename?: 'User' }
        & Pick<User, 'uid' | 'firstName' | 'lastName' | 'email' | 'photoURL'>
      ), storefront: (
        { __typename?: 'Storefront' }
        & Pick<Storefront, 'id' | 'businessName'>
        & { photos: (
          { __typename?: 'StorefrontStorageInfo' }
          & { main?: Maybe<(
            { __typename?: 'StorageFileInfo' }
            & Pick<StorageFileInfo, 'uri'>
          )> }
        ) }
      ) }
    )> }
  )> }
);

export type GetAdminStorefrontDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAdminStorefrontDetailQuery = (
  { __typename?: 'Query' }
  & { adminStorefront?: Maybe<(
    { __typename?: 'PaginatedAdminStorefront' }
    & { data: Array<(
      { __typename?: 'AdminStorefront' }
      & Pick<AdminStorefront, 'id' | 'status' | 'created_at' | 'businessName' | 'tagline' | 'description' | 'priceType' | 'priceLow' | 'priceHigh' | 'detail' | 'visualTagsJSON' | 'address' | 'city' | 'zipCode' | 'state' | 'instaUserId' | 'instaAccessToken' | 'catalogSortOrder' | 'hasPaymentSetup' | 'fulfillmentJSON' | 'categoryJSON' | 'dynamicLink' | 'approvalDate' | 'adminCategoryJSON' | 'startPauseDate' | 'endPauseDate' | 'measuredStatistics'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<User, 'uid' | 'displayName' | 'photoURL' | 'email' | 'phoneNumber'>
      ), category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'title' | 'uri'>
      )>, statistics: (
        { __typename?: 'StorefrontStatistics' }
        & Pick<StorefrontStatistics, 'profit' | 'thankYouNotes' | 'completedOrders'>
      ), catalogSummary?: Maybe<Array<Maybe<(
        { __typename?: 'CatalogSummary' }
        & Pick<CatalogSummary, 'catalogStatus' | 'count'>
      )>>>, photos: (
        { __typename?: 'StorefrontStorageInfo' }
        & { main?: Maybe<(
          { __typename?: 'StorageFileInfo' }
          & Pick<StorageFileInfo, 'uri'>
        )>, others?: Maybe<Array<Maybe<(
          { __typename?: 'StorageFileInfo' }
          & Pick<StorageFileInfo, 'uri'>
        )>>> }
      ), chatrooms: Array<(
        { __typename?: 'AdminStorefrontChatroom' }
        & Pick<AdminStorefrontChatroom, 'id' | 'link'>
        & { otherUser: (
          { __typename?: 'UserAdmin' }
          & Pick<UserAdmin, 'uid' | 'displayName'>
        ) }
      )>, storefrontTags?: Maybe<Array<Maybe<(
        { __typename?: 'AdminStorefrontTag' }
        & Pick<AdminStorefrontTag, 'tagId' | 'tag' | 'isAutoTag'>
      )>>> }
    )> }
  )> }
);

export type GetThankYouNotesQueryVariables = Exact<{
  storefrontId: Scalars['Int'];
}>;


export type GetThankYouNotesQuery = (
  { __typename?: 'Query' }
  & { comment?: Maybe<(
    { __typename?: 'PaginatedComment' }
    & { pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'maxPage'>
    )>, data: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'created_at' | 'body' | 'reactions' | 'rating'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'uid' | 'photoURL' | 'displayName'>
      ), order?: Maybe<(
        { __typename?: 'CommentOrder' }
        & Pick<CommentOrder, 'serviceName'>
      )> }
    )> }
  )> }
);

export type GetAdminCohortQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminCohortQuery = (
  { __typename?: 'Query' }
  & { adminGetCohort?: Maybe<Array<Maybe<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'cohortId' | 'created_at' | 'cohort' | 'cohortDescription' | 'userType'>
  )>>> }
);

export type GetDiscountCodeListQueryVariables = Exact<{
  discountCodeId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetDiscountCodeListQuery = (
  { __typename?: 'Query' }
  & { adminDiscountCodeList?: Maybe<(
    { __typename?: 'PaginatedAdminDiscountCode' }
    & { data: Array<(
      { __typename?: 'AdminDiscountCode' }
      & Pick<AdminDiscountCode, 'discountCodeId' | 'created_at' | 'code' | 'prefix' | 'discountType' | 'discountValue' | 'activeFrom' | 'activeTo' | 'restrictUseJSON' | 'orderMinimumAmount' | 'isReferral' | 'isActive' | 'adminNotes' | 'maximumDiscountAmount'>
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )> }
  )> }
);

export type GetAdminTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminTagsQuery = (
  { __typename?: 'Query' }
  & { adminTagList?: Maybe<Array<Maybe<(
    { __typename?: 'AdminTagSummary' }
    & Pick<AdminTagSummary, 'tagId' | 'tag' | 'countOfStorefronts'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagCategory' }
      & Pick<AdminTagCategory, 'categoryId' | 'categoryTitle'>
    )>>> }
  )>>> }
);

export type GetAdminTagDetailQueryVariables = Exact<{
  tagId: Scalars['Int'];
}>;


export type GetAdminTagDetailQuery = (
  { __typename?: 'Query' }
  & { adminTagDetail?: Maybe<(
    { __typename?: 'AdminTag' }
    & Pick<AdminTag, 'tagId' | 'tag'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagCategory' }
      & Pick<AdminTagCategory, 'categoryId' | 'categoryTitle'>
    )>>>, storefronts?: Maybe<Array<Maybe<(
      { __typename?: 'AdminTagStorefront' }
      & Pick<AdminTagStorefront, 'storefrontId' | 'businessName' | 'isAutoTag'>
    )>>> }
  )> }
);

export type GetAdminStripeStatusQueryVariables = Exact<{
  storefrontId: Scalars['Int'];
}>;


export type GetAdminStripeStatusQuery = (
  { __typename?: 'Query' }
  & { adminStripeStatus?: Maybe<(
    { __typename?: 'StripeStatus' }
    & Pick<StripeStatus, 'charges_enabled' | 'payouts_enabled'>
  )> }
);

export type GetAdminCategoryListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminCategoryListQuery = (
  { __typename?: 'Query' }
  & { adminCategoryList?: Maybe<Array<Maybe<(
    { __typename?: 'AdminCategory' }
    & Pick<AdminCategory, 'id' | 'title' | 'uri' | 'isVirtual' | 'canStorefrontAssign' | 'isSeasonal' | 'isMarketing' | 'active'>
  )>>> }
);

export type GetAdminConversationListQueryVariables = Exact<{
  conversationId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<UserType>;
  orderBy?: Maybe<AdminConversationOrderBy>;
  orderByOrder?: Maybe<AdminConversationOrderByOrder>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetAdminConversationListQuery = (
  { __typename?: 'Query' }
  & { adminConversationList?: Maybe<(
    { __typename?: 'PaginatedAdminConversationList' }
    & { data: Array<(
      { __typename?: 'AdminConversationList' }
      & Pick<AdminConversationList, 'conversationId' | 'created_at' | 'updated_at' | 'twilioConversationSid' | 'adminStatus'>
      & { buyerUser: (
        { __typename?: 'User' }
        & Pick<User, 'uid' | 'displayName' | 'email' | 'phoneNumber'>
      ), sellerUser: (
        { __typename?: 'ConversationUserWithStorefront' }
        & Pick<ConversationUserWithStorefront, 'uid' | 'displayName' | 'conversationUserType' | 'created_at' | 'isAnonymous'>
        & { storefront?: Maybe<(
          { __typename?: 'Storefront' }
          & Pick<Storefront, 'id' | 'businessName' | 'status'>
        )> }
      ), lastOutReach?: Maybe<(
        { __typename?: 'AdminConversationDetailMessage' }
        & Pick<AdminConversationDetailMessage, 'outReachType' | 'created_at' | 'twilioMessageSid'>
      )>, lastMessageBuyer?: Maybe<(
        { __typename?: 'AdminConversationDetailMessage' }
        & Pick<AdminConversationDetailMessage, 'created_at' | 'twilioMessageSid' | 'conversationMessageId' | 'messageIndex' | 'sentByUserId' | 'outReachType' | 'hasMedia' | 'body' | 'twilioPayload' | 'messageStatusByUserJSON'>
      )>, lastMessageSeller?: Maybe<(
        { __typename?: 'AdminConversationDetailMessage' }
        & Pick<AdminConversationDetailMessage, 'created_at' | 'twilioMessageSid'>
      )>, outReachStatistics?: Maybe<(
        { __typename?: 'AdminOutReachStatistics' }
        & Pick<AdminOutReachStatistics, 'totalOutReachCount'>
      )>, adminHasConversationWithBuyer?: Maybe<(
        { __typename?: 'HasConversationWithAdmin' }
        & Pick<HasConversationWithAdmin, 'conversationId'>
      )>, adminHasConversationWithSeller?: Maybe<(
        { __typename?: 'HasConversationWithAdmin' }
        & Pick<HasConversationWithAdmin, 'conversationId'>
      )> }
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )> }
  )> }
);

export type GetAdminConversationDetailQueryVariables = Exact<{
  conversation: Scalars['Int'];
}>;


export type GetAdminConversationDetailQuery = (
  { __typename?: 'Query' }
  & { adminConversationDetail?: Maybe<(
    { __typename?: 'AdminConversationDetail' }
    & { conversationHeader: (
      { __typename?: 'AdminConversationDetailHeader' }
      & Pick<AdminConversationDetailHeader, 'conversationId' | 'created_at' | 'updated_at' | 'twilioConversationSid'>
    ), conversationParticipants: (
      { __typename?: 'AdminConversationParticipants' }
      & { conversationOwnerUser: (
        { __typename?: 'UserAdmin' }
        & Pick<UserAdmin, 'uid' | 'displayName' | 'created_at'>
      ), conversationOtherUser: (
        { __typename?: 'UserAdmin' }
        & Pick<UserAdmin, 'uid' | 'displayName' | 'created_at'>
      ) }
    ), conversationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'AdminConversationDetailMessage' }
      & Pick<AdminConversationDetailMessage, 'conversationMessageId' | 'created_at' | 'messageIndex' | 'sentByUserId' | 'hasMedia' | 'body' | 'twilioMessageSid' | 'twilioPayload' | 'adminStatus'>
    )>>> }
  )> }
);

export type GetAdminCsConversationListQueryVariables = Exact<{
  userType?: Maybe<UserType>;
  adminStatus?: Maybe<ConversationAdminStatus>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetAdminCsConversationListQuery = (
  { __typename?: 'Query' }
  & { adminCSConversationList?: Maybe<(
    { __typename?: 'PaginatedAdminCSConversationList' }
    & { data: Array<(
      { __typename?: 'AdminCSConversationList' }
      & Pick<AdminCsConversationList, 'conversationId' | 'created_at' | 'twilioConversationSid' | 'unReadMessageCountByUser' | 'adminStatus'>
      & { lastMessage?: Maybe<(
        { __typename?: 'ConversationMessage' }
        & Pick<ConversationMessage, 'created_at' | 'messageIndex' | 'sentByUserId' | 'hasMedia' | 'body' | 'twilioMessageSid'>
      )>, otherUser: (
        { __typename?: 'ConversationUser' }
        & Pick<ConversationUser, 'uid' | 'displayName' | 'conversationUserType' | 'created_at' | 'isAnonymous'>
      ), storefrontJSON?: Maybe<(
        { __typename?: 'ConversationListStorefront' }
        & Pick<ConversationListStorefront, 'id' | 'businessName'>
      )> }
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationResult' }
      & Pick<PaginationResult, 'total' | 'currentPage'>
    )> }
  )> }
);

export type HasProxyConversationQueryVariables = Exact<{
  uid: Scalars['ID'];
  userType: UserType;
}>;


export type HasProxyConversationQuery = (
  { __typename?: 'Query' }
  & { hasProxyConversation?: Maybe<(
    { __typename?: 'HasConversationWithAdmin' }
    & Pick<HasConversationWithAdmin, 'conversationId'>
  )> }
);


export const DeleteUserAdminDocument = gql`
    mutation deleteUserAdmin($uid: String!) {
  deleteUser(uid: $uid)
}
    `;
export type DeleteUserAdminMutationFn = ApolloReactCommon.MutationFunction<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>;
export type DeleteUserAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>, 'mutation'>;

    export const DeleteUserAdminComponent = (props: DeleteUserAdminComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserAdminMutation, DeleteUserAdminMutationVariables> mutation={DeleteUserAdminDocument} {...props} />
    );
    

/**
 * __useDeleteUserAdminMutation__
 *
 * To run a mutation, you first call `useDeleteUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAdminMutation, { data, loading, error }] = useDeleteUserAdminMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>(DeleteUserAdminDocument, baseOptions);
      }
export type DeleteUserAdminMutationHookResult = ReturnType<typeof useDeleteUserAdminMutation>;
export type DeleteUserAdminMutationResult = ApolloReactCommon.MutationResult<DeleteUserAdminMutation>;
export type DeleteUserAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>;
export const ApproveStorefrontDocument = gql`
    mutation approveStorefront($id: Int!) {
  approveStorefront(id: $id) {
    id
    status
  }
}
    `;
export type ApproveStorefrontMutationFn = ApolloReactCommon.MutationFunction<ApproveStorefrontMutation, ApproveStorefrontMutationVariables>;
export type ApproveStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveStorefrontMutation, ApproveStorefrontMutationVariables>, 'mutation'>;

    export const ApproveStorefrontComponent = (props: ApproveStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveStorefrontMutation, ApproveStorefrontMutationVariables> mutation={ApproveStorefrontDocument} {...props} />
    );
    

/**
 * __useApproveStorefrontMutation__
 *
 * To run a mutation, you first call `useApproveStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveStorefrontMutation, { data, loading, error }] = useApproveStorefrontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveStorefrontMutation, ApproveStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveStorefrontMutation, ApproveStorefrontMutationVariables>(ApproveStorefrontDocument, baseOptions);
      }
export type ApproveStorefrontMutationHookResult = ReturnType<typeof useApproveStorefrontMutation>;
export type ApproveStorefrontMutationResult = ApolloReactCommon.MutationResult<ApproveStorefrontMutation>;
export type ApproveStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveStorefrontMutation, ApproveStorefrontMutationVariables>;
export const ActivateStorefrontDocument = gql`
    mutation activateStorefront($id: Int!) {
  activateStorefront(id: $id) {
    id
    status
  }
}
    `;
export type ActivateStorefrontMutationFn = ApolloReactCommon.MutationFunction<ActivateStorefrontMutation, ActivateStorefrontMutationVariables>;
export type ActivateStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivateStorefrontMutation, ActivateStorefrontMutationVariables>, 'mutation'>;

    export const ActivateStorefrontComponent = (props: ActivateStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<ActivateStorefrontMutation, ActivateStorefrontMutationVariables> mutation={ActivateStorefrontDocument} {...props} />
    );
    

/**
 * __useActivateStorefrontMutation__
 *
 * To run a mutation, you first call `useActivateStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateStorefrontMutation, { data, loading, error }] = useActivateStorefrontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateStorefrontMutation, ActivateStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateStorefrontMutation, ActivateStorefrontMutationVariables>(ActivateStorefrontDocument, baseOptions);
      }
export type ActivateStorefrontMutationHookResult = ReturnType<typeof useActivateStorefrontMutation>;
export type ActivateStorefrontMutationResult = ApolloReactCommon.MutationResult<ActivateStorefrontMutation>;
export type ActivateStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateStorefrontMutation, ActivateStorefrontMutationVariables>;
export const DeactivateStorefrontDocument = gql`
    mutation deactivateStorefront($id: Int!) {
  deactivateStorefront(id: $id) {
    id
    status
  }
}
    `;
export type DeactivateStorefrontMutationFn = ApolloReactCommon.MutationFunction<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables>;
export type DeactivateStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables>, 'mutation'>;

    export const DeactivateStorefrontComponent = (props: DeactivateStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables> mutation={DeactivateStorefrontDocument} {...props} />
    );
    

/**
 * __useDeactivateStorefrontMutation__
 *
 * To run a mutation, you first call `useDeactivateStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateStorefrontMutation, { data, loading, error }] = useDeactivateStorefrontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables>(DeactivateStorefrontDocument, baseOptions);
      }
export type DeactivateStorefrontMutationHookResult = ReturnType<typeof useDeactivateStorefrontMutation>;
export type DeactivateStorefrontMutationResult = ApolloReactCommon.MutationResult<DeactivateStorefrontMutation>;
export type DeactivateStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateStorefrontMutation, DeactivateStorefrontMutationVariables>;
export const DeleteStorefrontDocument = gql`
    mutation deleteStorefront($id: Int!) {
  deleteStorefront(id: $id)
}
    `;
export type DeleteStorefrontMutationFn = ApolloReactCommon.MutationFunction<DeleteStorefrontMutation, DeleteStorefrontMutationVariables>;
export type DeleteStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteStorefrontMutation, DeleteStorefrontMutationVariables>, 'mutation'>;

    export const DeleteStorefrontComponent = (props: DeleteStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteStorefrontMutation, DeleteStorefrontMutationVariables> mutation={DeleteStorefrontDocument} {...props} />
    );
    

/**
 * __useDeleteStorefrontMutation__
 *
 * To run a mutation, you first call `useDeleteStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorefrontMutation, { data, loading, error }] = useDeleteStorefrontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStorefrontMutation, DeleteStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStorefrontMutation, DeleteStorefrontMutationVariables>(DeleteStorefrontDocument, baseOptions);
      }
export type DeleteStorefrontMutationHookResult = ReturnType<typeof useDeleteStorefrontMutation>;
export type DeleteStorefrontMutationResult = ApolloReactCommon.MutationResult<DeleteStorefrontMutation>;
export type DeleteStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStorefrontMutation, DeleteStorefrontMutationVariables>;
export const RejectStorefrontDocument = gql`
    mutation rejectStorefront($id: Int!) {
  rejectStorefront(id: $id) {
    id
    status
  }
}
    `;
export type RejectStorefrontMutationFn = ApolloReactCommon.MutationFunction<RejectStorefrontMutation, RejectStorefrontMutationVariables>;
export type RejectStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RejectStorefrontMutation, RejectStorefrontMutationVariables>, 'mutation'>;

    export const RejectStorefrontComponent = (props: RejectStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<RejectStorefrontMutation, RejectStorefrontMutationVariables> mutation={RejectStorefrontDocument} {...props} />
    );
    

/**
 * __useRejectStorefrontMutation__
 *
 * To run a mutation, you first call `useRejectStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectStorefrontMutation, { data, loading, error }] = useRejectStorefrontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectStorefrontMutation, RejectStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectStorefrontMutation, RejectStorefrontMutationVariables>(RejectStorefrontDocument, baseOptions);
      }
export type RejectStorefrontMutationHookResult = ReturnType<typeof useRejectStorefrontMutation>;
export type RejectStorefrontMutationResult = ApolloReactCommon.MutationResult<RejectStorefrontMutation>;
export type RejectStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectStorefrontMutation, RejectStorefrontMutationVariables>;
export const SendNotificationDocument = gql`
    mutation sendNotification($input: NotifyInput!) {
  notify(input: $input)
}
    `;
export type SendNotificationMutationFn = ApolloReactCommon.MutationFunction<SendNotificationMutation, SendNotificationMutationVariables>;
export type SendNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendNotificationMutation, SendNotificationMutationVariables>, 'mutation'>;

    export const SendNotificationComponent = (props: SendNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendNotificationMutation, SendNotificationMutationVariables> mutation={SendNotificationDocument} {...props} />
    );
    

/**
 * __useSendNotificationMutation__
 *
 * To run a mutation, you first call `useSendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationMutation, { data, loading, error }] = useSendNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendNotificationMutation, SendNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendNotificationMutation, SendNotificationMutationVariables>(SendNotificationDocument, baseOptions);
      }
export type SendNotificationMutationHookResult = ReturnType<typeof useSendNotificationMutation>;
export type SendNotificationMutationResult = ApolloReactCommon.MutationResult<SendNotificationMutation>;
export type SendNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendNotificationMutation, SendNotificationMutationVariables>;
export const UpdateStorefrontDocument = gql`
    mutation UpdateStorefront($storefront: UpdateStorefrontInput!) {
  updateStorefront(storefront: $storefront) {
    id
    visualTagsJSON
  }
}
    `;
export type UpdateStorefrontMutationFn = ApolloReactCommon.MutationFunction<UpdateStorefrontMutation, UpdateStorefrontMutationVariables>;
export type UpdateStorefrontComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStorefrontMutation, UpdateStorefrontMutationVariables>, 'mutation'>;

    export const UpdateStorefrontComponent = (props: UpdateStorefrontComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStorefrontMutation, UpdateStorefrontMutationVariables> mutation={UpdateStorefrontDocument} {...props} />
    );
    

/**
 * __useUpdateStorefrontMutation__
 *
 * To run a mutation, you first call `useUpdateStorefrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorefrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorefrontMutation, { data, loading, error }] = useUpdateStorefrontMutation({
 *   variables: {
 *      storefront: // value for 'storefront'
 *   },
 * });
 */
export function useUpdateStorefrontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStorefrontMutation, UpdateStorefrontMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStorefrontMutation, UpdateStorefrontMutationVariables>(UpdateStorefrontDocument, baseOptions);
      }
export type UpdateStorefrontMutationHookResult = ReturnType<typeof useUpdateStorefrontMutation>;
export type UpdateStorefrontMutationResult = ApolloReactCommon.MutationResult<UpdateStorefrontMutation>;
export type UpdateStorefrontMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStorefrontMutation, UpdateStorefrontMutationVariables>;
export const UpsertStorefrontCatalogItemDocument = gql`
    mutation UpsertStorefrontCatalogItem($catalog: StorefrontCatalogInput!) {
  upsertStorefrontCatalogItem(catalog: $catalog) {
    catalogId
    status
    mediaSortOrder
  }
}
    `;
export type UpsertStorefrontCatalogItemMutationFn = ApolloReactCommon.MutationFunction<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables>;
export type UpsertStorefrontCatalogItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables>, 'mutation'>;

    export const UpsertStorefrontCatalogItemComponent = (props: UpsertStorefrontCatalogItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables> mutation={UpsertStorefrontCatalogItemDocument} {...props} />
    );
    

/**
 * __useUpsertStorefrontCatalogItemMutation__
 *
 * To run a mutation, you first call `useUpsertStorefrontCatalogItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStorefrontCatalogItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStorefrontCatalogItemMutation, { data, loading, error }] = useUpsertStorefrontCatalogItemMutation({
 *   variables: {
 *      catalog: // value for 'catalog'
 *   },
 * });
 */
export function useUpsertStorefrontCatalogItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables>(UpsertStorefrontCatalogItemDocument, baseOptions);
      }
export type UpsertStorefrontCatalogItemMutationHookResult = ReturnType<typeof useUpsertStorefrontCatalogItemMutation>;
export type UpsertStorefrontCatalogItemMutationResult = ApolloReactCommon.MutationResult<UpsertStorefrontCatalogItemMutation>;
export type UpsertStorefrontCatalogItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertStorefrontCatalogItemMutation, UpsertStorefrontCatalogItemMutationVariables>;
export const AdminCreateNotificationCenterDocument = gql`
    mutation AdminCreateNotificationCenter($notification: AdminNotificationInput!) {
  adminCreateNotificationCenter(notification: $notification) {
    notificationId
    created_at
    updated_at
    type
    userType
    payload
    expiry
    cohort
    deletedAt
    countOfUsers
  }
}
    `;
export type AdminCreateNotificationCenterMutationFn = ApolloReactCommon.MutationFunction<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables>;
export type AdminCreateNotificationCenterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables>, 'mutation'>;

    export const AdminCreateNotificationCenterComponent = (props: AdminCreateNotificationCenterComponentProps) => (
      <ApolloReactComponents.Mutation<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables> mutation={AdminCreateNotificationCenterDocument} {...props} />
    );
    

/**
 * __useAdminCreateNotificationCenterMutation__
 *
 * To run a mutation, you first call `useAdminCreateNotificationCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateNotificationCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateNotificationCenterMutation, { data, loading, error }] = useAdminCreateNotificationCenterMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useAdminCreateNotificationCenterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables>(AdminCreateNotificationCenterDocument, baseOptions);
      }
export type AdminCreateNotificationCenterMutationHookResult = ReturnType<typeof useAdminCreateNotificationCenterMutation>;
export type AdminCreateNotificationCenterMutationResult = ApolloReactCommon.MutationResult<AdminCreateNotificationCenterMutation>;
export type AdminCreateNotificationCenterMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminCreateNotificationCenterMutation, AdminCreateNotificationCenterMutationVariables>;
export const CreateDiscountCodeDocument = gql`
    mutation createDiscountCode($discountCode: AdminDiscountCodeInput!) {
  adminCreateDiscountCode(discountcode: $discountCode) {
    discountCodeId
    code
    prefix
    discountType
    activeFrom
    activeTo
    orderMinimumAmount
    restrictUseJSON
    adminNotes
    maximumDiscountAmount
    isReferral
  }
}
    `;
export type CreateDiscountCodeMutationFn = ApolloReactCommon.MutationFunction<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>;
export type CreateDiscountCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>, 'mutation'>;

    export const CreateDiscountCodeComponent = (props: CreateDiscountCodeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables> mutation={CreateDiscountCodeDocument} {...props} />
    );
    

/**
 * __useCreateDiscountCodeMutation__
 *
 * To run a mutation, you first call `useCreateDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountCodeMutation, { data, loading, error }] = useCreateDiscountCodeMutation({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useCreateDiscountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>(CreateDiscountCodeDocument, baseOptions);
      }
export type CreateDiscountCodeMutationHookResult = ReturnType<typeof useCreateDiscountCodeMutation>;
export type CreateDiscountCodeMutationResult = ApolloReactCommon.MutationResult<CreateDiscountCodeMutation>;
export type CreateDiscountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>;
export const AdminCreateTagDocument = gql`
    mutation AdminCreateTag($tag: CreateTagInput!) {
  adminCreateTag(tag: $tag) {
    tagId
    tag
    categories {
      categoryId
      categoryTitle
    }
    storefronts {
      storefrontId
      businessName
      isAutoTag
    }
  }
}
    `;
export type AdminCreateTagMutationFn = ApolloReactCommon.MutationFunction<AdminCreateTagMutation, AdminCreateTagMutationVariables>;
export type AdminCreateTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminCreateTagMutation, AdminCreateTagMutationVariables>, 'mutation'>;

    export const AdminCreateTagComponent = (props: AdminCreateTagComponentProps) => (
      <ApolloReactComponents.Mutation<AdminCreateTagMutation, AdminCreateTagMutationVariables> mutation={AdminCreateTagDocument} {...props} />
    );
    

/**
 * __useAdminCreateTagMutation__
 *
 * To run a mutation, you first call `useAdminCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTagMutation, { data, loading, error }] = useAdminCreateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useAdminCreateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminCreateTagMutation, AdminCreateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminCreateTagMutation, AdminCreateTagMutationVariables>(AdminCreateTagDocument, baseOptions);
      }
export type AdminCreateTagMutationHookResult = ReturnType<typeof useAdminCreateTagMutation>;
export type AdminCreateTagMutationResult = ApolloReactCommon.MutationResult<AdminCreateTagMutation>;
export type AdminCreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminCreateTagMutation, AdminCreateTagMutationVariables>;
export const AdminUpdateTagDocument = gql`
    mutation AdminUpdateTag($tag: UpdateTagInput!) {
  adminUpdateTag(tag: $tag) {
    tagId
    tag
    categories {
      categoryId
      categoryTitle
    }
    storefronts {
      storefrontId
      businessName
      isAutoTag
    }
  }
}
    `;
export type AdminUpdateTagMutationFn = ApolloReactCommon.MutationFunction<AdminUpdateTagMutation, AdminUpdateTagMutationVariables>;
export type AdminUpdateTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminUpdateTagMutation, AdminUpdateTagMutationVariables>, 'mutation'>;

    export const AdminUpdateTagComponent = (props: AdminUpdateTagComponentProps) => (
      <ApolloReactComponents.Mutation<AdminUpdateTagMutation, AdminUpdateTagMutationVariables> mutation={AdminUpdateTagDocument} {...props} />
    );
    

/**
 * __useAdminUpdateTagMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTagMutation, { data, loading, error }] = useAdminUpdateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useAdminUpdateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminUpdateTagMutation, AdminUpdateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminUpdateTagMutation, AdminUpdateTagMutationVariables>(AdminUpdateTagDocument, baseOptions);
      }
export type AdminUpdateTagMutationHookResult = ReturnType<typeof useAdminUpdateTagMutation>;
export type AdminUpdateTagMutationResult = ApolloReactCommon.MutationResult<AdminUpdateTagMutation>;
export type AdminUpdateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminUpdateTagMutation, AdminUpdateTagMutationVariables>;
export const AdminDeleteTagDocument = gql`
    mutation AdminDeleteTag($tagId: Int!) {
  adminDeleteTag(tagId: $tagId)
}
    `;
export type AdminDeleteTagMutationFn = ApolloReactCommon.MutationFunction<AdminDeleteTagMutation, AdminDeleteTagMutationVariables>;
export type AdminDeleteTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminDeleteTagMutation, AdminDeleteTagMutationVariables>, 'mutation'>;

    export const AdminDeleteTagComponent = (props: AdminDeleteTagComponentProps) => (
      <ApolloReactComponents.Mutation<AdminDeleteTagMutation, AdminDeleteTagMutationVariables> mutation={AdminDeleteTagDocument} {...props} />
    );
    

/**
 * __useAdminDeleteTagMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTagMutation, { data, loading, error }] = useAdminDeleteTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useAdminDeleteTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminDeleteTagMutation, AdminDeleteTagMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminDeleteTagMutation, AdminDeleteTagMutationVariables>(AdminDeleteTagDocument, baseOptions);
      }
export type AdminDeleteTagMutationHookResult = ReturnType<typeof useAdminDeleteTagMutation>;
export type AdminDeleteTagMutationResult = ApolloReactCommon.MutationResult<AdminDeleteTagMutation>;
export type AdminDeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminDeleteTagMutation, AdminDeleteTagMutationVariables>;
export const AdminUpdateStorefrontTagDocument = gql`
    mutation AdminUpdateStorefrontTag($tag: UpdateStorefrontTagInput!) {
  adminUpdateStorefrontTag(tag: $tag) {
    tagId
    tag
    isAutoTag
  }
}
    `;
export type AdminUpdateStorefrontTagMutationFn = ApolloReactCommon.MutationFunction<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables>;
export type AdminUpdateStorefrontTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables>, 'mutation'>;

    export const AdminUpdateStorefrontTagComponent = (props: AdminUpdateStorefrontTagComponentProps) => (
      <ApolloReactComponents.Mutation<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables> mutation={AdminUpdateStorefrontTagDocument} {...props} />
    );
    

/**
 * __useAdminUpdateStorefrontTagMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStorefrontTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStorefrontTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStorefrontTagMutation, { data, loading, error }] = useAdminUpdateStorefrontTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useAdminUpdateStorefrontTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables>(AdminUpdateStorefrontTagDocument, baseOptions);
      }
export type AdminUpdateStorefrontTagMutationHookResult = ReturnType<typeof useAdminUpdateStorefrontTagMutation>;
export type AdminUpdateStorefrontTagMutationResult = ApolloReactCommon.MutationResult<AdminUpdateStorefrontTagMutation>;
export type AdminUpdateStorefrontTagMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminUpdateStorefrontTagMutation, AdminUpdateStorefrontTagMutationVariables>;
export const PauseStorefrontWithDateDocument = gql`
    mutation PauseStorefrontWithDate($storefront: PauseStorefrontwithDate!) {
  pauseStorefrontwithDate(storefront: $storefront) {
    id
    status
  }
}
    `;
export type PauseStorefrontWithDateMutationFn = ApolloReactCommon.MutationFunction<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables>;
export type PauseStorefrontWithDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables>, 'mutation'>;

    export const PauseStorefrontWithDateComponent = (props: PauseStorefrontWithDateComponentProps) => (
      <ApolloReactComponents.Mutation<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables> mutation={PauseStorefrontWithDateDocument} {...props} />
    );
    

/**
 * __usePauseStorefrontWithDateMutation__
 *
 * To run a mutation, you first call `usePauseStorefrontWithDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseStorefrontWithDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseStorefrontWithDateMutation, { data, loading, error }] = usePauseStorefrontWithDateMutation({
 *   variables: {
 *      storefront: // value for 'storefront'
 *   },
 * });
 */
export function usePauseStorefrontWithDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables>) {
        return ApolloReactHooks.useMutation<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables>(PauseStorefrontWithDateDocument, baseOptions);
      }
export type PauseStorefrontWithDateMutationHookResult = ReturnType<typeof usePauseStorefrontWithDateMutation>;
export type PauseStorefrontWithDateMutationResult = ApolloReactCommon.MutationResult<PauseStorefrontWithDateMutation>;
export type PauseStorefrontWithDateMutationOptions = ApolloReactCommon.BaseMutationOptions<PauseStorefrontWithDateMutation, PauseStorefrontWithDateMutationVariables>;
export const UpdatePrimaryImageDocument = gql`
    mutation UpdatePrimaryImage($storefront: AdminStorefrontPrimaryImageInput!) {
  adminUpdateStorefrontPrimaryImage(storefront: $storefront) {
    id
    status
    photos {
      main {
        uri
      }
    }
  }
}
    `;
export type UpdatePrimaryImageMutationFn = ApolloReactCommon.MutationFunction<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables>;
export type UpdatePrimaryImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables>, 'mutation'>;

    export const UpdatePrimaryImageComponent = (props: UpdatePrimaryImageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables> mutation={UpdatePrimaryImageDocument} {...props} />
    );
    

/**
 * __useUpdatePrimaryImageMutation__
 *
 * To run a mutation, you first call `useUpdatePrimaryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrimaryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrimaryImageMutation, { data, loading, error }] = useUpdatePrimaryImageMutation({
 *   variables: {
 *      storefront: // value for 'storefront'
 *   },
 * });
 */
export function useUpdatePrimaryImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables>(UpdatePrimaryImageDocument, baseOptions);
      }
export type UpdatePrimaryImageMutationHookResult = ReturnType<typeof useUpdatePrimaryImageMutation>;
export type UpdatePrimaryImageMutationResult = ApolloReactCommon.MutationResult<UpdatePrimaryImageMutation>;
export type UpdatePrimaryImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePrimaryImageMutation, UpdatePrimaryImageMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: Int!) {
  deleteComment(id: $id)
}
    `;
export type DeleteCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;
export type DeleteCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCommentMutation, DeleteCommentMutationVariables>, 'mutation'>;

    export const DeleteCommentComponent = (props: DeleteCommentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> mutation={DeleteCommentDocument} {...props} />
    );
    

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, baseOptions);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = ApolloReactCommon.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const AdminDiscountCodeUpdateDocument = gql`
    mutation AdminDiscountCodeUpdate($discountCode: AdminDiscountCodeUpdateInput!) {
  adminDiscountCodeUpdate(discountcode: $discountCode) {
    discountCodeId
    code
    prefix
    discountType
    activeFrom
    activeTo
    orderMinimumAmount
    adminNotes
  }
}
    `;
export type AdminDiscountCodeUpdateMutationFn = ApolloReactCommon.MutationFunction<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables>;
export type AdminDiscountCodeUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables>, 'mutation'>;

    export const AdminDiscountCodeUpdateComponent = (props: AdminDiscountCodeUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables> mutation={AdminDiscountCodeUpdateDocument} {...props} />
    );
    

/**
 * __useAdminDiscountCodeUpdateMutation__
 *
 * To run a mutation, you first call `useAdminDiscountCodeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDiscountCodeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDiscountCodeUpdateMutation, { data, loading, error }] = useAdminDiscountCodeUpdateMutation({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useAdminDiscountCodeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables>(AdminDiscountCodeUpdateDocument, baseOptions);
      }
export type AdminDiscountCodeUpdateMutationHookResult = ReturnType<typeof useAdminDiscountCodeUpdateMutation>;
export type AdminDiscountCodeUpdateMutationResult = ApolloReactCommon.MutationResult<AdminDiscountCodeUpdateMutation>;
export type AdminDiscountCodeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminDiscountCodeUpdateMutation, AdminDiscountCodeUpdateMutationVariables>;
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($user: AdminDeleteUserInput!) {
  adminDeleteUser(user: $user) {
    deletedUserId
    deletedStorefrontId
  }
}
    `;
export type AdminDeleteUserMutationFn = ApolloReactCommon.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export type AdminDeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>, 'mutation'>;

    export const AdminDeleteUserComponent = (props: AdminDeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables> mutation={AdminDeleteUserDocument} {...props} />
    );
    

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, baseOptions);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = ApolloReactCommon.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const CreateConversationDocument = gql`
    mutation CreateConversation($conversation: Create1to1ConversationInput!) {
  createConversation(conversation: $conversation) {
    conversationId
    relatedConversationId
  }
}
    `;
export type CreateConversationMutationFn = ApolloReactCommon.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;
export type CreateConversationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateConversationMutation, CreateConversationMutationVariables>, 'mutation'>;

    export const CreateConversationComponent = (props: CreateConversationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateConversationMutation, CreateConversationMutationVariables> mutation={CreateConversationDocument} {...props} />
    );
    

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      conversation: // value for 'conversation'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, baseOptions);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = ApolloReactCommon.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;
export const AdminConversationUpdateStatusDocument = gql`
    mutation AdminConversationUpdateStatus($conversation: AdminConversationUpdateStatus!) {
  adminConversationUpdateStatus(conversation: $conversation) {
    conversationId
    adminStatus
  }
}
    `;
export type AdminConversationUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables>;
export type AdminConversationUpdateStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables>, 'mutation'>;

    export const AdminConversationUpdateStatusComponent = (props: AdminConversationUpdateStatusComponentProps) => (
      <ApolloReactComponents.Mutation<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables> mutation={AdminConversationUpdateStatusDocument} {...props} />
    );
    

/**
 * __useAdminConversationUpdateStatusMutation__
 *
 * To run a mutation, you first call `useAdminConversationUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminConversationUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminConversationUpdateStatusMutation, { data, loading, error }] = useAdminConversationUpdateStatusMutation({
 *   variables: {
 *      conversation: // value for 'conversation'
 *   },
 * });
 */
export function useAdminConversationUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables>(AdminConversationUpdateStatusDocument, baseOptions);
      }
export type AdminConversationUpdateStatusMutationHookResult = ReturnType<typeof useAdminConversationUpdateStatusMutation>;
export type AdminConversationUpdateStatusMutationResult = ApolloReactCommon.MutationResult<AdminConversationUpdateStatusMutation>;
export type AdminConversationUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminConversationUpdateStatusMutation, AdminConversationUpdateStatusMutationVariables>;
export const AdminResyncTwilioMessagesDocument = gql`
    mutation AdminResyncTwilioMessages($twilioConversationSid: String!) {
  adminResyncTwilioMessages(twilioConversationSid: $twilioConversationSid)
}
    `;
export type AdminResyncTwilioMessagesMutationFn = ApolloReactCommon.MutationFunction<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables>;
export type AdminResyncTwilioMessagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables>, 'mutation'>;

    export const AdminResyncTwilioMessagesComponent = (props: AdminResyncTwilioMessagesComponentProps) => (
      <ApolloReactComponents.Mutation<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables> mutation={AdminResyncTwilioMessagesDocument} {...props} />
    );
    

/**
 * __useAdminResyncTwilioMessagesMutation__
 *
 * To run a mutation, you first call `useAdminResyncTwilioMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResyncTwilioMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResyncTwilioMessagesMutation, { data, loading, error }] = useAdminResyncTwilioMessagesMutation({
 *   variables: {
 *      twilioConversationSid: // value for 'twilioConversationSid'
 *   },
 * });
 */
export function useAdminResyncTwilioMessagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables>(AdminResyncTwilioMessagesDocument, baseOptions);
      }
export type AdminResyncTwilioMessagesMutationHookResult = ReturnType<typeof useAdminResyncTwilioMessagesMutation>;
export type AdminResyncTwilioMessagesMutationResult = ApolloReactCommon.MutationResult<AdminResyncTwilioMessagesMutation>;
export type AdminResyncTwilioMessagesMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminResyncTwilioMessagesMutation, AdminResyncTwilioMessagesMutationVariables>;
export const AuthUserDocument = gql`
    query authUser {
  me {
    uid
    type
  }
}
    `;
export type AuthUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthUserQuery, AuthUserQueryVariables>, 'query'>;

    export const AuthUserComponent = (props: AuthUserComponentProps) => (
      <ApolloReactComponents.Query<AuthUserQuery, AuthUserQueryVariables> query={AuthUserDocument} {...props} />
    );
    

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, baseOptions);
      }
export function useAuthUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, baseOptions);
        }
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<typeof useAuthUserLazyQuery>;
export type AuthUserQueryResult = ApolloReactCommon.QueryResult<AuthUserQuery, AuthUserQueryVariables>;
export const AuthUserDataDocument = gql`
    query authUserData {
  me {
    uid
  }
}
    `;
export type AuthUserDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthUserDataQuery, AuthUserDataQueryVariables>, 'query'>;

    export const AuthUserDataComponent = (props: AuthUserDataComponentProps) => (
      <ApolloReactComponents.Query<AuthUserDataQuery, AuthUserDataQueryVariables> query={AuthUserDataDocument} {...props} />
    );
    

/**
 * __useAuthUserDataQuery__
 *
 * To run a query within a React component, call `useAuthUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthUserDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthUserDataQuery, AuthUserDataQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthUserDataQuery, AuthUserDataQueryVariables>(AuthUserDataDocument, baseOptions);
      }
export function useAuthUserDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthUserDataQuery, AuthUserDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthUserDataQuery, AuthUserDataQueryVariables>(AuthUserDataDocument, baseOptions);
        }
export type AuthUserDataQueryHookResult = ReturnType<typeof useAuthUserDataQuery>;
export type AuthUserDataLazyQueryHookResult = ReturnType<typeof useAuthUserDataLazyQuery>;
export type AuthUserDataQueryResult = ApolloReactCommon.QueryResult<AuthUserDataQuery, AuthUserDataQueryVariables>;
export const GetDashboardDocument = gql`
    query getDashboard {
  adminDashboard {
    title
    icon
    value
    type
  }
}
    `;
export type GetDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDashboardQuery, GetDashboardQueryVariables>, 'query'>;

    export const GetDashboardComponent = (props: GetDashboardComponentProps) => (
      <ApolloReactComponents.Query<GetDashboardQuery, GetDashboardQueryVariables> query={GetDashboardDocument} {...props} />
    );
    

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
      }
export function useGetDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = ApolloReactCommon.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  category {
    id
    title
    uri
    isVirtual
    canStorefrontAssign
  }
}
    `;
export type GetCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCategoriesQuery, GetCategoriesQueryVariables>, 'query'>;

    export const GetCategoriesComponent = (props: GetCategoriesComponentProps) => (
      <ApolloReactComponents.Query<GetCategoriesQuery, GetCategoriesQueryVariables> query={GetCategoriesDocument} {...props} />
    );
    

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
      }
export function useGetCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = ApolloReactCommon.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($page: Int, $pageSize: Int, $role: UserType, $uid: ID, $search: String) {
  usersAdmin(filter: {role: $role, uid: $uid, search: $search}, pagination: {page: $page, perPage: $pageSize}) {
    data {
      uid
      zip
      city
      streetAddress
      photoURL
      displayName
      email
      type
      created_at
    }
    pagination {
      total
      currentPage
    }
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      role: // value for 'role'
 *      uid: // value for 'uid'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetCatalogDocument = gql`
    query GetCatalog($storefrontId: Int!) {
  catalog(filter: {storefrontId: $storefrontId}) {
    catalogId
    storefrontId
    price
    created_at
    updated_at
    name
    description
    priceType
    price
    pricingDescription
    status
    fulfillmentMethodJSON
    mediaSortOrder
    allowSelectQuantity
    media {
      cloudfrontURL
      contentType
      mediaId
      storefrontCardURL
    }
  }
}
    `;
export type GetCatalogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCatalogQuery, GetCatalogQueryVariables>, 'query'> & ({ variables: GetCatalogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCatalogComponent = (props: GetCatalogComponentProps) => (
      <ApolloReactComponents.Query<GetCatalogQuery, GetCatalogQueryVariables> query={GetCatalogDocument} {...props} />
    );
    

/**
 * __useGetCatalogQuery__
 *
 * To run a query within a React component, call `useGetCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogQuery({
 *   variables: {
 *      storefrontId: // value for 'storefrontId'
 *   },
 * });
 */
export function useGetCatalogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogQuery, GetCatalogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogQuery, GetCatalogQueryVariables>(GetCatalogDocument, baseOptions);
      }
export function useGetCatalogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogQuery, GetCatalogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogQuery, GetCatalogQueryVariables>(GetCatalogDocument, baseOptions);
        }
export type GetCatalogQueryHookResult = ReturnType<typeof useGetCatalogQuery>;
export type GetCatalogLazyQueryHookResult = ReturnType<typeof useGetCatalogLazyQuery>;
export type GetCatalogQueryResult = ApolloReactCommon.QueryResult<GetCatalogQuery, GetCatalogQueryVariables>;
export const GetAdminStorefrontsDocument = gql`
    query getAdminStorefronts($page: Int, $pageSize: Int, $categoryId: Int, $storefrontStatus: StorefrontStatus, $priceType: PriceType, $date: Sorting, $hasPaymentSetup: Boolean, $businessName: String) {
  adminStorefront(filter: {categoryId: $categoryId, priceType: $priceType, status: $storefrontStatus, date: $date, hasPaymentSetup: $hasPaymentSetup, businessName: $businessName}, pagination: {page: $page, perPage: $pageSize}) {
    data {
      id
      businessName
      created_at
      status
      state
      category {
        id
        uri
        title
      }
      priceLow
      priceHigh
      priceType
      owner {
        uid
        displayName
      }
      photos {
        main {
          uri
        }
      }
      hasPaymentSetup
      categoryJSON
      dynamicLink
      catalogSummary {
        count
        catalogStatus
      }
    }
    pagination {
      total
      currentPage
    }
  }
}
    `;
export type GetAdminStorefrontsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>, 'query'>;

    export const GetAdminStorefrontsComponent = (props: GetAdminStorefrontsComponentProps) => (
      <ApolloReactComponents.Query<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables> query={GetAdminStorefrontsDocument} {...props} />
    );
    

/**
 * __useGetAdminStorefrontsQuery__
 *
 * To run a query within a React component, call `useGetAdminStorefrontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorefrontsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorefrontsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      categoryId: // value for 'categoryId'
 *      storefrontStatus: // value for 'storefrontStatus'
 *      priceType: // value for 'priceType'
 *      date: // value for 'date'
 *      hasPaymentSetup: // value for 'hasPaymentSetup'
 *      businessName: // value for 'businessName'
 *   },
 * });
 */
export function useGetAdminStorefrontsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>(GetAdminStorefrontsDocument, baseOptions);
      }
export function useGetAdminStorefrontsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>(GetAdminStorefrontsDocument, baseOptions);
        }
export type GetAdminStorefrontsQueryHookResult = ReturnType<typeof useGetAdminStorefrontsQuery>;
export type GetAdminStorefrontsLazyQueryHookResult = ReturnType<typeof useGetAdminStorefrontsLazyQuery>;
export type GetAdminStorefrontsQueryResult = ApolloReactCommon.QueryResult<GetAdminStorefrontsQuery, GetAdminStorefrontsQueryVariables>;
export const GetOrdersDocument = gql`
    query getOrders($page: Int, $pageSize: Int, $status: OrderStatus) {
  adminOrder(filter: {status: $status}, pagination: {page: $page, perPage: $pageSize}) {
    pagination {
      total
      currentPage
    }
    data {
      id
      buyer {
        uid
        firstName
        lastName
        email
        photoURL
      }
      storefront {
        id
        businessName
        photos {
          main {
            uri
          }
        }
      }
      amount
      additionalCost
      status
      created_at
      completedAt
      dueDate
    }
  }
}
    `;
export type GetOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrdersQuery, GetOrdersQueryVariables>, 'query'>;

    export const GetOrdersComponent = (props: GetOrdersComponentProps) => (
      <ApolloReactComponents.Query<GetOrdersQuery, GetOrdersQueryVariables> query={GetOrdersDocument} {...props} />
    );
    

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
      }
export function useGetOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = ApolloReactCommon.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetAdminStorefrontDetailDocument = gql`
    query getAdminStorefrontDetail($id: Int!) {
  adminStorefront(filter: {Storefront: {id: $id}}) {
    data {
      id
      owner {
        uid
        displayName
        photoURL
        email
        phoneNumber
      }
      category {
        id
        title
        uri
      }
      statistics {
        profit
        thankYouNotes
        completedOrders
      }
      status
      created_at
      businessName
      tagline
      description
      priceType
      priceLow
      priceHigh
      detail
      visualTagsJSON
      address
      city
      zipCode
      state
      catalogSummary {
        catalogStatus
        count
      }
      instaUserId
      instaAccessToken
      catalogSortOrder
      photos {
        main {
          uri
        }
        others {
          uri
        }
      }
      hasPaymentSetup
      chatrooms {
        id
        link
        otherUser {
          uid
          displayName
        }
      }
      fulfillmentJSON
      categoryJSON
      dynamicLink
      storefrontTags {
        tagId
        tag
        isAutoTag
      }
      approvalDate
      adminCategoryJSON
      startPauseDate
      endPauseDate
      measuredStatistics(filter: {storefrontId: $id})
    }
  }
}
    `;
export type GetAdminStorefrontDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>, 'query'> & ({ variables: GetAdminStorefrontDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAdminStorefrontDetailComponent = (props: GetAdminStorefrontDetailComponentProps) => (
      <ApolloReactComponents.Query<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables> query={GetAdminStorefrontDetailDocument} {...props} />
    );
    

/**
 * __useGetAdminStorefrontDetailQuery__
 *
 * To run a query within a React component, call `useGetAdminStorefrontDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStorefrontDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStorefrontDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminStorefrontDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>(GetAdminStorefrontDetailDocument, baseOptions);
      }
export function useGetAdminStorefrontDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>(GetAdminStorefrontDetailDocument, baseOptions);
        }
export type GetAdminStorefrontDetailQueryHookResult = ReturnType<typeof useGetAdminStorefrontDetailQuery>;
export type GetAdminStorefrontDetailLazyQueryHookResult = ReturnType<typeof useGetAdminStorefrontDetailLazyQuery>;
export type GetAdminStorefrontDetailQueryResult = ApolloReactCommon.QueryResult<GetAdminStorefrontDetailQuery, GetAdminStorefrontDetailQueryVariables>;
export const GetThankYouNotesDocument = gql`
    query getThankYouNotes($storefrontId: Int!) {
  comment(filter: {storefrontId: $storefrontId}) {
    pagination {
      maxPage
    }
    data {
      id
      user {
        uid
        photoURL
        displayName
      }
      created_at
      body
      reactions
      rating
      order {
        serviceName
      }
    }
  }
}
    `;
export type GetThankYouNotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>, 'query'> & ({ variables: GetThankYouNotesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetThankYouNotesComponent = (props: GetThankYouNotesComponentProps) => (
      <ApolloReactComponents.Query<GetThankYouNotesQuery, GetThankYouNotesQueryVariables> query={GetThankYouNotesDocument} {...props} />
    );
    

/**
 * __useGetThankYouNotesQuery__
 *
 * To run a query within a React component, call `useGetThankYouNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThankYouNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThankYouNotesQuery({
 *   variables: {
 *      storefrontId: // value for 'storefrontId'
 *   },
 * });
 */
export function useGetThankYouNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>(GetThankYouNotesDocument, baseOptions);
      }
export function useGetThankYouNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>(GetThankYouNotesDocument, baseOptions);
        }
export type GetThankYouNotesQueryHookResult = ReturnType<typeof useGetThankYouNotesQuery>;
export type GetThankYouNotesLazyQueryHookResult = ReturnType<typeof useGetThankYouNotesLazyQuery>;
export type GetThankYouNotesQueryResult = ApolloReactCommon.QueryResult<GetThankYouNotesQuery, GetThankYouNotesQueryVariables>;
export const GetAdminCohortDocument = gql`
    query getAdminCohort {
  adminGetCohort {
    cohortId
    created_at
    cohort
    cohortDescription
    userType
  }
}
    `;
export type GetAdminCohortComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminCohortQuery, GetAdminCohortQueryVariables>, 'query'>;

    export const GetAdminCohortComponent = (props: GetAdminCohortComponentProps) => (
      <ApolloReactComponents.Query<GetAdminCohortQuery, GetAdminCohortQueryVariables> query={GetAdminCohortDocument} {...props} />
    );
    

/**
 * __useGetAdminCohortQuery__
 *
 * To run a query within a React component, call `useGetAdminCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCohortQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminCohortQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminCohortQuery, GetAdminCohortQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminCohortQuery, GetAdminCohortQueryVariables>(GetAdminCohortDocument, baseOptions);
      }
export function useGetAdminCohortLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminCohortQuery, GetAdminCohortQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminCohortQuery, GetAdminCohortQueryVariables>(GetAdminCohortDocument, baseOptions);
        }
export type GetAdminCohortQueryHookResult = ReturnType<typeof useGetAdminCohortQuery>;
export type GetAdminCohortLazyQueryHookResult = ReturnType<typeof useGetAdminCohortLazyQuery>;
export type GetAdminCohortQueryResult = ApolloReactCommon.QueryResult<GetAdminCohortQuery, GetAdminCohortQueryVariables>;
export const GetDiscountCodeListDocument = gql`
    query getDiscountCodeList($discountCodeId: Int, $code: String, $prefix: String, $discountType: DiscountType, $page: Int, $pageSize: Int) {
  adminDiscountCodeList(filter: {discountCodeId: $discountCodeId, code: $code, prefix: $prefix, discountType: $discountType}, pagination: {page: $page, perPage: $pageSize}) {
    data {
      discountCodeId
      created_at
      code
      prefix
      discountType
      discountValue
      activeFrom
      activeTo
      restrictUseJSON
      orderMinimumAmount
      isReferral
      isActive
      adminNotes
      maximumDiscountAmount
    }
    pagination {
      total
      currentPage
    }
  }
}
    `;
export type GetDiscountCodeListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>, 'query'>;

    export const GetDiscountCodeListComponent = (props: GetDiscountCodeListComponentProps) => (
      <ApolloReactComponents.Query<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables> query={GetDiscountCodeListDocument} {...props} />
    );
    

/**
 * __useGetDiscountCodeListQuery__
 *
 * To run a query within a React component, call `useGetDiscountCodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountCodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountCodeListQuery({
 *   variables: {
 *      discountCodeId: // value for 'discountCodeId'
 *      code: // value for 'code'
 *      prefix: // value for 'prefix'
 *      discountType: // value for 'discountType'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetDiscountCodeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>(GetDiscountCodeListDocument, baseOptions);
      }
export function useGetDiscountCodeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>(GetDiscountCodeListDocument, baseOptions);
        }
export type GetDiscountCodeListQueryHookResult = ReturnType<typeof useGetDiscountCodeListQuery>;
export type GetDiscountCodeListLazyQueryHookResult = ReturnType<typeof useGetDiscountCodeListLazyQuery>;
export type GetDiscountCodeListQueryResult = ApolloReactCommon.QueryResult<GetDiscountCodeListQuery, GetDiscountCodeListQueryVariables>;
export const GetAdminTagsDocument = gql`
    query getAdminTags {
  adminTagList {
    tagId
    tag
    countOfStorefronts
    categories {
      categoryId
      categoryTitle
    }
  }
}
    `;
export type GetAdminTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminTagsQuery, GetAdminTagsQueryVariables>, 'query'>;

    export const GetAdminTagsComponent = (props: GetAdminTagsComponentProps) => (
      <ApolloReactComponents.Query<GetAdminTagsQuery, GetAdminTagsQueryVariables> query={GetAdminTagsDocument} {...props} />
    );
    

/**
 * __useGetAdminTagsQuery__
 *
 * To run a query within a React component, call `useGetAdminTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminTagsQuery, GetAdminTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminTagsQuery, GetAdminTagsQueryVariables>(GetAdminTagsDocument, baseOptions);
      }
export function useGetAdminTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminTagsQuery, GetAdminTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminTagsQuery, GetAdminTagsQueryVariables>(GetAdminTagsDocument, baseOptions);
        }
export type GetAdminTagsQueryHookResult = ReturnType<typeof useGetAdminTagsQuery>;
export type GetAdminTagsLazyQueryHookResult = ReturnType<typeof useGetAdminTagsLazyQuery>;
export type GetAdminTagsQueryResult = ApolloReactCommon.QueryResult<GetAdminTagsQuery, GetAdminTagsQueryVariables>;
export const GetAdminTagDetailDocument = gql`
    query getAdminTagDetail($tagId: Int!) {
  adminTagDetail(tagId: $tagId) {
    tagId
    tag
    categories {
      categoryId
      categoryTitle
    }
    storefronts {
      storefrontId
      businessName
      isAutoTag
    }
  }
}
    `;
export type GetAdminTagDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>, 'query'> & ({ variables: GetAdminTagDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAdminTagDetailComponent = (props: GetAdminTagDetailComponentProps) => (
      <ApolloReactComponents.Query<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables> query={GetAdminTagDetailDocument} {...props} />
    );
    

/**
 * __useGetAdminTagDetailQuery__
 *
 * To run a query within a React component, call `useGetAdminTagDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTagDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTagDetailQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useGetAdminTagDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>(GetAdminTagDetailDocument, baseOptions);
      }
export function useGetAdminTagDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>(GetAdminTagDetailDocument, baseOptions);
        }
export type GetAdminTagDetailQueryHookResult = ReturnType<typeof useGetAdminTagDetailQuery>;
export type GetAdminTagDetailLazyQueryHookResult = ReturnType<typeof useGetAdminTagDetailLazyQuery>;
export type GetAdminTagDetailQueryResult = ApolloReactCommon.QueryResult<GetAdminTagDetailQuery, GetAdminTagDetailQueryVariables>;
export const GetAdminStripeStatusDocument = gql`
    query getAdminStripeStatus($storefrontId: Int!) {
  adminStripeStatus(storefrontId: $storefrontId) {
    charges_enabled
    payouts_enabled
  }
}
    `;
export type GetAdminStripeStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>, 'query'> & ({ variables: GetAdminStripeStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAdminStripeStatusComponent = (props: GetAdminStripeStatusComponentProps) => (
      <ApolloReactComponents.Query<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables> query={GetAdminStripeStatusDocument} {...props} />
    );
    

/**
 * __useGetAdminStripeStatusQuery__
 *
 * To run a query within a React component, call `useGetAdminStripeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStripeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStripeStatusQuery({
 *   variables: {
 *      storefrontId: // value for 'storefrontId'
 *   },
 * });
 */
export function useGetAdminStripeStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>(GetAdminStripeStatusDocument, baseOptions);
      }
export function useGetAdminStripeStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>(GetAdminStripeStatusDocument, baseOptions);
        }
export type GetAdminStripeStatusQueryHookResult = ReturnType<typeof useGetAdminStripeStatusQuery>;
export type GetAdminStripeStatusLazyQueryHookResult = ReturnType<typeof useGetAdminStripeStatusLazyQuery>;
export type GetAdminStripeStatusQueryResult = ApolloReactCommon.QueryResult<GetAdminStripeStatusQuery, GetAdminStripeStatusQueryVariables>;
export const GetAdminCategoryListDocument = gql`
    query getAdminCategoryList {
  adminCategoryList {
    id
    title
    uri
    isVirtual
    canStorefrontAssign
    isSeasonal
    isMarketing
    active
  }
}
    `;
export type GetAdminCategoryListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>, 'query'>;

    export const GetAdminCategoryListComponent = (props: GetAdminCategoryListComponentProps) => (
      <ApolloReactComponents.Query<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables> query={GetAdminCategoryListDocument} {...props} />
    );
    

/**
 * __useGetAdminCategoryListQuery__
 *
 * To run a query within a React component, call `useGetAdminCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminCategoryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>(GetAdminCategoryListDocument, baseOptions);
      }
export function useGetAdminCategoryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>(GetAdminCategoryListDocument, baseOptions);
        }
export type GetAdminCategoryListQueryHookResult = ReturnType<typeof useGetAdminCategoryListQuery>;
export type GetAdminCategoryListLazyQueryHookResult = ReturnType<typeof useGetAdminCategoryListLazyQuery>;
export type GetAdminCategoryListQueryResult = ApolloReactCommon.QueryResult<GetAdminCategoryListQuery, GetAdminCategoryListQueryVariables>;
export const GetAdminConversationListDocument = gql`
    query getAdminConversationList($conversationId: Int, $userId: ID, $userType: UserType, $orderBy: AdminConversationOrderBy, $orderByOrder: AdminConversationOrderByOrder, $adminStatus: ConversationAdminStatus, $page: Int, $pageSize: Int) {
  adminConversationList(filter: {conversationId: $conversationId, userId: $userId, userType: $userType, orderBy: $orderBy, orderByOrder: $orderByOrder, adminStatus: $adminStatus}, pagination: {page: $page, perPage: $pageSize}) {
    data {
      conversationId
      created_at
      updated_at
      twilioConversationSid
      adminStatus
      buyerUser {
        uid
        displayName
        email
        phoneNumber
      }
      sellerUser {
        uid
        displayName
        conversationUserType
        created_at
        isAnonymous
        storefront {
          id
          businessName
          status
        }
      }
      lastOutReach {
        outReachType
        created_at
        twilioMessageSid
      }
      lastMessageBuyer {
        created_at
        twilioMessageSid
        conversationMessageId
        messageIndex
        sentByUserId
        outReachType
        hasMedia
        body
        twilioPayload
        messageStatusByUserJSON
      }
      lastMessageSeller {
        created_at
        twilioMessageSid
      }
      outReachStatistics {
        totalOutReachCount
      }
      adminHasConversationWithBuyer {
        conversationId
      }
      adminHasConversationWithSeller {
        conversationId
      }
    }
    pagination {
      total
      currentPage
    }
  }
}
    `;
export type GetAdminConversationListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>, 'query'>;

    export const GetAdminConversationListComponent = (props: GetAdminConversationListComponentProps) => (
      <ApolloReactComponents.Query<GetAdminConversationListQuery, GetAdminConversationListQueryVariables> query={GetAdminConversationListDocument} {...props} />
    );
    

/**
 * __useGetAdminConversationListQuery__
 *
 * To run a query within a React component, call `useGetAdminConversationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminConversationListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminConversationListQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      userId: // value for 'userId'
 *      userType: // value for 'userType'
 *      orderBy: // value for 'orderBy'
 *      orderByOrder: // value for 'orderByOrder'
 *      adminStatus: // value for 'adminStatus'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAdminConversationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>(GetAdminConversationListDocument, baseOptions);
      }
export function useGetAdminConversationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>(GetAdminConversationListDocument, baseOptions);
        }
export type GetAdminConversationListQueryHookResult = ReturnType<typeof useGetAdminConversationListQuery>;
export type GetAdminConversationListLazyQueryHookResult = ReturnType<typeof useGetAdminConversationListLazyQuery>;
export type GetAdminConversationListQueryResult = ApolloReactCommon.QueryResult<GetAdminConversationListQuery, GetAdminConversationListQueryVariables>;
export const GetAdminConversationDetailDocument = gql`
    query getAdminConversationDetail($conversation: Int!) {
  adminConversationDetail(conversation: $conversation) {
    conversationHeader {
      conversationId
      created_at
      updated_at
      twilioConversationSid
    }
    conversationParticipants {
      conversationOwnerUser {
        uid
        displayName
        created_at
      }
      conversationOtherUser {
        uid
        displayName
        created_at
      }
    }
    conversationMessages {
      conversationMessageId
      created_at
      messageIndex
      sentByUserId
      hasMedia
      body
      twilioMessageSid
      twilioPayload
      adminStatus
    }
  }
}
    `;
export type GetAdminConversationDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>, 'query'> & ({ variables: GetAdminConversationDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAdminConversationDetailComponent = (props: GetAdminConversationDetailComponentProps) => (
      <ApolloReactComponents.Query<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables> query={GetAdminConversationDetailDocument} {...props} />
    );
    

/**
 * __useGetAdminConversationDetailQuery__
 *
 * To run a query within a React component, call `useGetAdminConversationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminConversationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminConversationDetailQuery({
 *   variables: {
 *      conversation: // value for 'conversation'
 *   },
 * });
 */
export function useGetAdminConversationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>(GetAdminConversationDetailDocument, baseOptions);
      }
export function useGetAdminConversationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>(GetAdminConversationDetailDocument, baseOptions);
        }
export type GetAdminConversationDetailQueryHookResult = ReturnType<typeof useGetAdminConversationDetailQuery>;
export type GetAdminConversationDetailLazyQueryHookResult = ReturnType<typeof useGetAdminConversationDetailLazyQuery>;
export type GetAdminConversationDetailQueryResult = ApolloReactCommon.QueryResult<GetAdminConversationDetailQuery, GetAdminConversationDetailQueryVariables>;
export const GetAdminCsConversationListDocument = gql`
    query getAdminCSConversationList($userType: UserType, $adminStatus: ConversationAdminStatus, $page: Int, $pageSize: Int) {
  adminCSConversationList(filter: {userType: $userType, adminStatus: $adminStatus}, pagination: {page: $page, perPage: $pageSize}) {
    data {
      conversationId
      created_at
      twilioConversationSid
      lastMessage {
        created_at
        messageIndex
        sentByUserId
        hasMedia
        body
        twilioMessageSid
      }
      otherUser {
        uid
        displayName
        conversationUserType
        created_at
        isAnonymous
      }
      storefrontJSON {
        id
        businessName
      }
      unReadMessageCountByUser
      adminStatus
    }
    pagination {
      total
      currentPage
    }
  }
}
    `;
export type GetAdminCsConversationListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>, 'query'>;

    export const GetAdminCsConversationListComponent = (props: GetAdminCsConversationListComponentProps) => (
      <ApolloReactComponents.Query<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables> query={GetAdminCsConversationListDocument} {...props} />
    );
    

/**
 * __useGetAdminCsConversationListQuery__
 *
 * To run a query within a React component, call `useGetAdminCsConversationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCsConversationListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCsConversationListQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *      adminStatus: // value for 'adminStatus'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAdminCsConversationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>(GetAdminCsConversationListDocument, baseOptions);
      }
export function useGetAdminCsConversationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>(GetAdminCsConversationListDocument, baseOptions);
        }
export type GetAdminCsConversationListQueryHookResult = ReturnType<typeof useGetAdminCsConversationListQuery>;
export type GetAdminCsConversationListLazyQueryHookResult = ReturnType<typeof useGetAdminCsConversationListLazyQuery>;
export type GetAdminCsConversationListQueryResult = ApolloReactCommon.QueryResult<GetAdminCsConversationListQuery, GetAdminCsConversationListQueryVariables>;
export const HasProxyConversationDocument = gql`
    query hasProxyConversation($uid: ID!, $userType: UserType!) {
  hasProxyConversation(userId: $uid, userType: $userType) {
    conversationId
  }
}
    `;
export type HasProxyConversationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HasProxyConversationQuery, HasProxyConversationQueryVariables>, 'query'> & ({ variables: HasProxyConversationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HasProxyConversationComponent = (props: HasProxyConversationComponentProps) => (
      <ApolloReactComponents.Query<HasProxyConversationQuery, HasProxyConversationQueryVariables> query={HasProxyConversationDocument} {...props} />
    );
    

/**
 * __useHasProxyConversationQuery__
 *
 * To run a query within a React component, call `useHasProxyConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasProxyConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasProxyConversationQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useHasProxyConversationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasProxyConversationQuery, HasProxyConversationQueryVariables>) {
        return ApolloReactHooks.useQuery<HasProxyConversationQuery, HasProxyConversationQueryVariables>(HasProxyConversationDocument, baseOptions);
      }
export function useHasProxyConversationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasProxyConversationQuery, HasProxyConversationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasProxyConversationQuery, HasProxyConversationQueryVariables>(HasProxyConversationDocument, baseOptions);
        }
export type HasProxyConversationQueryHookResult = ReturnType<typeof useHasProxyConversationQuery>;
export type HasProxyConversationLazyQueryHookResult = ReturnType<typeof useHasProxyConversationLazyQuery>;
export type HasProxyConversationQueryResult = ApolloReactCommon.QueryResult<HasProxyConversationQuery, HasProxyConversationQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    