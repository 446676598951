import React from 'react';
import Logo from 'images/logo-login.svg';

import * as S from './styles';

type Props = {};

const Centered: React.FC<Props> = ({ children }) => (
  <S.Wrap>
    <S.Logo src={Logo} alt="Logo" />
    {children}
  </S.Wrap>
);

export default Centered;
