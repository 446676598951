import * as React from 'react';
import { NavLink } from 'react-router-dom';
import User from 'images/user-menu.svg';
import Dashboard from 'images/dashboard-menu.svg';
import Storefront from 'images/storefront-menu.svg';
import * as S from './styles';
import Discounts from 'images/discountIcon.png';
import Messages from 'images/messages.svg';

interface Props {
  className?: string;
  name: string;
  iconName?: string;
  to: string;
  iconStyles?: Object;
  hideSidebar?: () => any;
}

const MenuLink = ({ className, to, name, hideSidebar }: Props) => {
  let icon = null;

  switch (name) {
    case 'Users':
      icon = <S.LogoImg src={User} />;
      break;
    case 'Storefronts':
      icon = <S.LogoImg src={Storefront} />;
      break;
    case 'Dashboard':
      icon = <S.LogoImg src={Dashboard} />;
      break;
    case 'Orders':
      icon = <S.LogoIcon type="book" />;
      break;
    case 'Discounts':
      icon = <S.LogoImg style={{ height: 20, marginRight: 10 }} src={Discounts} />;
      break;
    case 'Notification':
      icon = <S.LogoIcon type="notification" />;
      break;
    case 'Categories':
      icon = <S.LogoIcon type="hdd" />;
      break;
    case 'Tags Library':
      icon = <S.LogoIcon type="tags" />;
      break;
    case 'Messages':
      icon = (
        <S.LogoImg
          style={{
            height: 20,
            marginRight: 10,
          }}
          src={Messages}
        />
      );
      break;
    default:
      icon = null;
      break;
  }

  return (
    <NavLink to={to} className={className} onClick={hideSidebar}>
      {icon}
      {name}
    </NavLink>
  );
};

export default MenuLink;
