import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import Cookie from 'js-cookie';

const logout = async (client: ApolloClient<NormalizedCacheObject>): Promise<void> => {
  Cookie.remove('token');
  await client.cache.reset();
  window.location.href = '/login';
};

export default logout;
