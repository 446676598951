import styled from 'styled-components';

export const Wrap = styled.div`
  width: calc(100vw - (100vw - 100%));
`;

export const Section = styled.div`
  display: flex;
`;

export const Content = styled.div`
  background-color: #fbfaf9;
  width: 100%;
  height: 93vh;
  overflow: scroll;
`;
