import React, { useEffect } from 'react';
import app from 'config/firebase';
import { compose } from 'recompose';
import { message } from 'antd';
import { Redirect } from 'react-router';
import Loading from 'components/Loading';
import Router from 'containers/Router';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { useAuthUserQuery, UserType } from 'store';
import { useRouter } from 'hooks';
import { getAuth, signOut } from 'firebase/auth';

type Props = {
  location: { pathname: string };
};

const App: React.FC<Props> = () => {
  const router = useRouter();

  const pathname = router?.location?.pathname;

  const { data: authQuery, loading } = useAuthUserQuery();

  const signinStep = authQuery?.me?.uid;
  const type = authQuery?.me?.type;
  const isLoggedIn = !!signinStep;

  const isLoaded = authQuery?.me?.type;
  const isOther = authQuery?.me?.type === (UserType.Buyer || UserType.Seller);
  useEffect(() => {
    if (!loading && isLoaded && isOther) {
      message.error('You are not allowed to access this content!');
      const auth = getAuth(app);
      try {
        signOut(auth);
      } catch (error) {
        console.log('error signing out');
      }
    }
  }, [loading, isLoaded, isOther]);

  if (loading) return <Loading />;
  if (isLoggedIn && pathname === '/login' && type === UserType.Admin)
    return <Redirect to="/dashboard" />;
  else if (isLoggedIn && pathname === '/login' && type === UserType.Ambassador)
    return <Redirect to="/storefronts" />;
  else if ((!isLoggedIn || isOther) && pathname !== '/login') return <Redirect to="/login" />;
  return <Router />;
};

export default compose<Props, {}>(withErrorBoundary)(App);
