const chain = <TArgs extends any[], TReturn extends any>(
  ...funcs: (((...args: TArgs) => TReturn) | undefined)[]
) => {
  return (...args: TArgs) => {
    const results = funcs.map((func) => func && func(...args));
    return results[results.length - 1];
  };
};

export default chain;
