import Cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, from, ApolloLink, HttpLink } from '@apollo/client';
import config from '../config';
import { onError } from '@apollo/client/link/error';

const authLinkContext = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => ({
    headers: {
      authorization: Cookie.get('token') ? `Bearer ${Cookie.get('token')}` : null,
      ...headers,
    },
  }));
  return forward(operation);
});

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    );
  }
});

const terminatingLink = new HttpLink({
  uri: `${config.bffUri}/graphql`,
  credentials: 'include',
});

export const client = new ApolloClient({
  uri: `${config.bffUri}/graphql`,
  link: from([errorLink, authLinkContext, terminatingLink]),
  cache: cache,
  defaultOptions: { query: { fetchPolicy: 'no-cache' } },
});

export default client;
