import { initializeApp } from 'firebase/app';
import { ref, getStorage } from 'firebase/storage';

let config = {
  apiKey: 'AIzaSyCPZM8hWx36A4Av7kWOwKH2GcjT-1keEEo',
  authDomain: 'wtenth-production.firebaseapp.com',
  projectId: 'wtenth-production',
  storageBucket: 'gs://wtenth-production-uploads/',
};

if (process.env.REACT_APP_CUSTOM_ENV !== 'production') {
  config = {
    apiKey: 'AIzaSyDSzBMnXIILm1VggpOF6eSrN-WdFQ6Q6n0',
    authDomain: 'wtenth-staging.firebaseapp.com',
    projectId: 'wtenth-staging',
    storageBucket: 'gs://wtenth-staging-uploads/',
  };
}

const app = initializeApp(config);
const storage = getStorage();
export const uploadsBucket = ref(storage);

export default app;
