import styled from 'styled-components';
import { colors } from 'theme';

const HEADER_HEIGHT = '60px';

export const Wrap = styled.div`
  height: ${HEADER_HEIGHT};
  background-color: #3d3f40;
  color: ${colors.white};
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  margin-left: 36px;
`;

export const VersionInfo = styled.div`
  margin-left: 4px;
  font-weight: 700;
  font-size: 10px;
  align-self: flex-end;
  margin-bottom: 12px;
`;

export const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.1;
  margin-left: 75px;
`;

export const MainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > p {
    font-size: 16px;
    font-style: italic;
    line-height: 21px;
    color: #ffffff;
    margin: auto 0;
    margin-left: 26px;
  }
`;

export const NameWrap = styled.div`
  margin-right: 45px;
  display: flex;

  & > p {
    margin: auto 0;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    margin-right: 20px;
  }
`;

export const ProfileImg = styled.img`
  height: 40px;
  width: 35px;
  border-radius: 50px;
`;
