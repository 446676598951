import styled from 'styled-components';
import { Modal } from 'antd';

export const Wrap = styled(Modal)`
  padding: 24px;
  min-width: 40vw;
`;

export const Body = styled.div`
  padding: 48px;
`;

export const Item = styled.div`
  text-align: center;
`;

export const Actions = styled.div`
  display: inline-block;
`;
