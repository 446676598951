import styled from 'styled-components';
import { Spin as ANTDSpin } from 'antd';

export const Wrap = styled.div<{ height: string | number }>`
  height: ${({ height }) => height};
`;

export const Spin = styled(ANTDSpin)`
  margin: auto;
  display: block;
  top: 40%;
  position: relative;
`;
