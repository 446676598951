import React from 'react';
import { Icon } from 'antd';

import * as S from './styles';
import { ErrorInfo } from './types';

const { useState, useCallback } = React;

type Props = {
  error: Error;
  info: ErrorInfo;
};

const AppError: React.FC<Props> = ({ error, info }) => {
  const [isVisible, setVisible] = useState(false);

  const handleHide = useCallback(() => setVisible(false), []);

  if (!isVisible) return null;
  if (process.env.ENVIRONMENT === 'production') {
    return (
      <S.WrapProduction>
        <h1>
          <Icon type="frown-o" style={{ marginRight: 10 }} />
          {`Sorry, something went wrong.`}
        </h1>
        <p>{`We're working on it and we'll get it fixed as soon as we can.`}</p>
        <i onClick={handleHide}>Hide error</i>
      </S.WrapProduction>
    );
  }
  return (
    <S.Wrap>
      <S.Wrap>
        <b>{error.message}</b>
        {info.componentStack.split(/\r?\n/).map((line: React.ReactNode) => (
          <p>{line}</p>
        ))}
      </S.Wrap>
    </S.Wrap>
  );
};

export default AppError;
