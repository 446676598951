import React from 'react';

export { default as login } from './login';
export { default as logout } from './logout';
export { default as version } from './version';
export { default as format } from './format';
export { default as chain } from './chain';
export { default as graphqlSorting } from './graphqlSorting';
export { default as imageUpload } from './imageUpload';

export const useForceStateUpdate = () => {
  const [, setValue] = React.useState(0);
  return () => setValue((value) => value + 1);
};
