import { useEffect, useState } from 'react';
import app from 'config/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useCurrentUser = () => {
  const auth = getAuth(app);
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, [auth]);
  return currentUser;
};

export default useCurrentUser;
