import styled from 'styled-components';
import { Modal, Select as ANTDSelect } from 'antd';

export const Wrap = styled(Modal)`
  width: 100%;
  & .input {
    margin-bottom: 8px;
  }
`;

export const Body = styled.div`
  padding: 28px 48px;
  height: 60vh;
`;

export const Select = styled(ANTDSelect)`
  width: 300px;
`;
export const Option = styled(ANTDSelect.Option)``;

export const TitleContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const SectionTitleRow = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
`;

export const SectionTitle = styled.text`
  font-size: 16px;
  font-weight: bold;
`;

export const SectionText = styled.text`
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 10px;
`;

export const CharacterCountRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 15px;
`;

export const CharacterCounter = styled.text`
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;
