import styled from 'styled-components';
import { colors } from 'theme';

export const Wrap = styled.div`
  border: 1px solid ${colors.greyLight};
  padding: 15px 30px;
  border-radius: 13px;
  font-size: 12px;
  max-width: 500px;
  margin: auto;
  max-height: 300px;
  overflow: scroll;
  margin-top: 20px;

  & b {
    font-size: 20px;
  }
`;

export const WrapProduction = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  padding: 10px;
`;
