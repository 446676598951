import React, { useCallback, useRef, useState } from 'react';

import { Modal, Input, Form, message, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Loading from 'components/Loading';
import {
  useAdminCreateNotificationCenterMutation,
  useGetCategoriesQuery,
  useGetAdminStorefrontsQuery,
  NotificationType,
  useGetAdminCohortQuery,
  StorefrontStatus,
} from 'store';

import * as S from './styles';
const { Option } = Select;

type Props = {
  visible: boolean;
  categoryId?: number;
  onClose: () => any;
  onCategoryChange?: (category?: number) => any;
  onAfterAnyChange?: () => any;
} & FormComponentProps;

const NotificationsModal: React.FC<Props> = ({ visible, onClose, form, onAfterAnyChange }) => {
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;

  const [adminCreateNotificationCenter, { loading: createNotificationLoading }] =
    useAdminCreateNotificationCenterMutation({
      onCompleted: () => setIsConfirmModalVisible(false),
    });
  const [categoryId, setCategoryId] = useState<number>();
  const [businessName, setBusinessName] = useState<string>();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const [valueObj, setValueObj] = useState<any>({});
  const [cohortName, setCohortName] = useState<string>();
  const [cohortId, setCohortId] = useState<number>();
  const [businessId, setBusinessId] = useState<number>();
  const [categoryName, setCategoryname] = useState<string>();
  const [typeOfNotification, setTypeOfNotification] = useState<NotificationType>(
    NotificationType.Notification
  );

  const businessNameRef = useRef(businessName);
  const [localScopeBusinessName, setLocalScopeBusinessName] = useState<string>();
  const [charCount, setCharCount] = useState<number>(0);

  const { data, error } = useGetCategoriesQuery();
  const { data: businesses, loading: loadingStorefronts } = useGetAdminStorefrontsQuery({
    variables: {
      businessName,
      storefrontStatus: StorefrontStatus.Active,
    },
  });

  const { data: cohort, loading } = useGetAdminCohortQuery();

  const cohortData = cohort?.adminGetCohort;

  const getStorefronts = (q: typeof businesses) => q?.adminStorefront?.data;
  const storefrontsReturned = getStorefronts(businesses);

  const businessOptions = storefrontsReturned?.map((item: any) => (
    <S.Option key={item.id} value={item.businessName}>
      {item.businessName}
    </S.Option>
  ));

  const cohortDetails = cohortData?.map((item: any) => (
    <S.Option key={item?.cohort} value={item?.cohortId} title={item?.cohort}>
      {item?.cohort}
    </S.Option>
  ));

  if (error) throw new Error(error.message);

  const closeModal = () => {
    onClose();
    form.resetFields();
    setLocalScopeBusinessName('');
    businessNameRef.current = '';
  };

  const handleBusinessSearch = (businessName: string) => {
    if (businessName) {
      setBusinessName && setBusinessName(businessName);
      onAfterAnyChange && onAfterAnyChange();
    } else {
      setBusinessName(undefined);
    }
  };

  const handleSendNotification = async (
    values: any,
    typeOfNotification: NotificationType,
    businessId?: number
  ) => {
    try {
      await adminCreateNotificationCenter({
        variables: {
          notification: {
            type: typeOfNotification,
            destination: values.businessName
              ? businessId
              : values.category
              ? values.category
              : null,
            cohortId: values.groupFilter || null,
            userId: values.uids || null,
            payload: {
              message: values.body,
            },
          },
        },
      });
      message.success(`Notification successfully submitted`);
      resetFields();
    } catch (e) {
      message.error(`Submitting request failed: ${JSON.stringify(e)}`);
    }
  };

  return (
    <Modal
      style={styles.wrap}
      closable
      centered
      visible={visible}
      onCancel={closeModal}
      okType="primary"
      okText="Send Notification"
      onOk={useCallback(() => {
        validateFields(async (err, values) => {
          setValueObj(values);
          // Error handling
          const errorMessage = 'Please enter a message';
          const errorNoUsers = 'Please select a user option';
          if (err) {
            message.error(`Validation failed: ${JSON.stringify(err)}`);
            return;
          }

          if (!values.body || (!values.uids && !values.groupFilter)) {
            !values.body ? message.error(errorMessage) : message.error(errorNoUsers);
            return;
          } else {
            setIsConfirmModalVisible(true);
          }

          // Selecting type
          if (values.businessName) {
            setTypeOfNotification(NotificationType.Storefront);
          } else if (values.category) {
            setTypeOfNotification(NotificationType.Category);
          } else {
            setTypeOfNotification(NotificationType.Notification);
          }
        });
      }, [validateFields])}
      maskClosable={false}
      okButtonProps={{ loading, disabled: loading }}
      width={800}
    >
      <S.Body>
        {/* Confirmation modal start*/}
        <Modal
          visible={isConfirmModalVisible}
          title="Are you sure you want to send the following?"
          okText="Yes"
          cancelText="No"
          width={500}
          closable
          okButtonProps={{
            loading: createNotificationLoading,
            disabled: createNotificationLoading,
          }}
          onOk={() => {
            handleSendNotification(valueObj, typeOfNotification, businessId);
          }}
          onCancel={() => setIsConfirmModalVisible(false)}
        >
          <>
            <div> User: {cohortName || valueObj.uids}</div>
            <div>
              Destination:{' '}
              {valueObj.businessName
                ? valueObj.businessName
                : valueObj.category
                ? categoryName
                : 'No destination'}
            </div>
            <div>Message: {valueObj.body}</div>
          </>
        </Modal>
        {/* Confirmation modal end*/}

        {loading ? (
          <Loading isLoading={loading} />
        ) : (
          <>
            <S.TitleContainer>
              <S.Title>Promotional Notification</S.Title>
            </S.TitleContainer>
            {/* User start  REQUIRED*/}
            <S.SectionTitleRow>
              <S.SectionTitle>User</S.SectionTitle>
            </S.SectionTitleRow>

            <S.Row>
              {/* cohort selection */}
              <Form.Item>
                {getFieldDecorator('groupFilter', { rules: [] })(
                  <Select
                    style={{ width: 250 }}
                    placeholder="Select a Cohort"
                    value={cohortId}
                    disabled={!!getFieldValue('uids')}
                    onChange={(e: number, option: any) => {
                      setCohortName(option.key);
                      setCohortId(e);
                    }}
                  >
                    <S.Option key="empty-value" value={undefined}>
                      {' '}
                    </S.Option>
                    {cohortDetails}
                  </Select>
                )}
              </Form.Item>
              <S.SectionText> Or </S.SectionText>

              {/* uid input */}
              <Form.Item>
                {getFieldDecorator('uids', { rules: [] })(
                  <Input
                    style={{ width: 250 }}
                    disabled={!!getFieldValue('groupFilter')}
                    className="input"
                    placeholder="Enter Uid"
                  />
                )}
              </Form.Item>
            </S.Row>
            {/* User end */}

            {/* Destination start */}
            <S.SectionTitleRow>
              <S.SectionTitle>Link Destination</S.SectionTitle>
            </S.SectionTitleRow>
            <S.SectionText>
              When the user selects a push notification without a location, she is taken to the
              Message Center, on the app. When the user selects a notification without a location,
              on the Message Center, she is taken to the Home Screen.
            </S.SectionText>

            <S.Row>
              {/* Business name selection */}
              <Form.Item>
                {getFieldDecorator('businessName', {
                  rules: [],
                })(
                  <Select
                    showSearch
                    style={{ width: 250 }}
                    placeholder="Search a store"
                    value={localScopeBusinessName}
                    onChange={(e: string, options: any) => {
                      setLocalScopeBusinessName(e);
                      setBusinessId(options.key);
                    }}
                    onSearch={handleBusinessSearch}
                    disabled={!!getFieldValue('category')}
                    loading={loadingStorefronts}
                  >
                    <S.Option key="empty-value" value={undefined}>
                      {' '}
                    </S.Option>
                    {businessOptions}
                  </Select>
                )}
              </Form.Item>

              <S.SectionText> Or </S.SectionText>

              {/* Category selection */}
              <Form.Item>
                {getFieldDecorator('category', { rules: [] })(
                  <Select
                    style={{ width: 250 }}
                    placeholder="Select a Category"
                    onChange={(e: number, options: any) => {
                      setCategoryname(options.key);
                      setCategoryId(e);
                    }}
                    value={categoryId}
                    disabled={!!getFieldValue('businessName')}
                  >
                    <S.Option key="empty-value" value={undefined}>
                      {' '}
                    </S.Option>
                    {data?.category.map((item: any) => {
                      return (
                        <Option value={item.id} key={item.title}>
                          {item.title}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </S.Row>

            {/* Destination end */}

            {/* Message Start REQUIRED*/}
            <S.SectionTitleRow>
              <S.SectionTitle>Message</S.SectionTitle>
            </S.SectionTitleRow>

            <Form.Item required>
              <S.CharacterCountRow>
                <S.CharacterCounter
                  style={{
                    color: charCount > 90 ? 'red' : 'black',
                  }}
                >
                  {charCount} characters
                </S.CharacterCounter>
              </S.CharacterCountRow>
              {getFieldDecorator('body')(
                <Input.TextArea
                  onChange={(e: any) => {
                    const value = e.target.value;
                    setCharCount(value.length);
                  }}
                  className="input"
                  placeholder="The recommendation is to keep it up to 40 characters “This week only: 30% off all home baking goods”"
                />
              )}
            </Form.Item>
            {/* Message end */}
          </>
        )}
      </S.Body>
    </Modal>
  );
};

export default Form.create<Props>()(NotificationsModal);

const styles = {
  wrap: {
    width: '100%',
    input: {
      marginBottom: 8,
      width: 250,
    },
  },
};
