import React from "react";
import styled from "styled-components";
import book from "./book.png";
import cake from "./cake.png";
import camera from "./camera.png";
import etc from "./etc.png";
import feather from "./feather.png";
import fitness from "./fitness.png";
import house from "./house.png";
import mirror from "./mirror.png";

const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const selectIcon = (name: string) => {
  let icon;

  switch (name) {
    case "fitness":
      icon = fitness;
      break;
    case "camera":
      icon = camera;
      break;
    case "house":
      icon = house;
      break;
    case "cake":
      icon = cake;
      break;
    case "book":
      icon = book;
      break;
    case "feather":
      icon = feather;
      break;
    case "mirror":
      icon = mirror;
      break;
    case "etc":
      icon = etc;
      break;
    default:
      return null;
  }

  return displayIcon(icon);
};

export const displayIcon = (uri: string) => {
  return <Icon src={uri} alt="Category icon" />;
};

export { book, cake, camera, etc, feather, fitness, house, mirror, selectIcon };
