export type VersionInfo = { version: string; timestamp: number };

const version = async (): Promise<VersionInfo> => {
  try {
    const res = await fetch('/version.json');
    return await res.json();
  } catch (e) {
    console.error(e);
    return { version: 'x.x.x', timestamp: new Date().getTime() };
  }
};

export default version;
