import styled from 'styled-components';
import BackgroundImage from 'images/bg-login.png';
import { colors } from 'theme';

export const Wrap = styled.div`
  height: 100vh;
  background-color: ${colors.greyWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('${BackgroundImage}');
`;

export const Logo = styled.img`
  height: 64px;
  width: 130px;
  margin-bottom: 30px;
`;
