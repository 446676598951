import React from 'react';
import DynamicLinkGenerator from 'components/DynamicLinkGenerator';
import { displayIcon } from 'images/category';

import * as S from './styles';
import { useGetCategoriesQuery } from 'store';
import Loading from 'components/Loading';
import { Modal } from 'antd';

type Props = {
  visible: boolean;
  onClose: () => any;
};

const displayNone = { style: { display: 'none' } };

const SingleCategory: React.FC<{ span: number; category: any }> = ({ span, category }) => {
  return (
    <S.Item>
      <S.Actions>
        <DynamicLinkGenerator linkButton={'false'} additionalString={`categoryId=${category.id}`} />
      </S.Actions>
      {displayIcon(category.uri)}
      {category.title}
    </S.Item>
  );
};

const CategoriesModal: React.FC<Props> = ({ onClose, visible }) => {
  const { data: categories, loading } = useGetCategoriesQuery();

  return (
    <Modal
      style={styles.wrap}
      closable
      centered
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      okButtonProps={displayNone}
    >
      <S.Body>
        {loading ? (
          <Loading isLoading={loading} />
        ) : (
          <>
            <div>
              {(categories?.category ?? []).map((category: any) => (
                <SingleCategory span={10} category={category} key={category.id} />
              ))}
            </div>
          </>
        )}
      </S.Body>
    </Modal>
  );
};

export default CategoriesModal;

const styles = {
  wrap: {
    padding: 24,
    minWidth: 40,
  },
};
