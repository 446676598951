import React, { useCallback } from 'react';
import { message } from 'antd';

import * as S from './styles';

type Props = {
  additionalString?: string;
  storefrontDynamicLink?: string | null;
  linkButton?: string;
};

const mapDynamicLink = (additionalString: string) =>
  `https://apps.apple.com/us/app/west-tenth/id1478134804?ls=1&${additionalString}`;

const DynamicLinkGenerator: React.FunctionComponent<Props> = ({
  storefrontDynamicLink,
  additionalString,
  linkButton,
}) => {
  return (
    <S.ButtonContainer>
      <S.Button
        // FIXME this was giving me an error... made me switch to string from boolean...
        // @ts-ignore
        linkbutton={linkButton.toString() || 'false'}
        type="link"
        onClick={useCallback(() => {
          // Create new element
          var el = document.createElement('textarea');
          el.value = storefrontDynamicLink
            ? storefrontDynamicLink!
            : mapDynamicLink(additionalString!);
          el.setAttribute('readonly', '');
          (el as any).style = { position: 'absolute', left: '-9999px' };
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          message.success('Link was successfully copied!');
        }, [storefrontDynamicLink, additionalString])}
      >
        <div
          style={{
            textDecoration: linkButton ? 'underline' : 'none',
            letterSpacing: 0.2,
          }}
        >
          Copy Deeplink
        </div>
      </S.Button>
    </S.ButtonContainer>
  );
};

export default DynamicLinkGenerator;
