// ON_STARTUP: change bffUri for your one

const getBffUri = () => {
  switch (process.env.REACT_APP_CUSTOM_ENV) {
    case 'production':
      return 'https://api.wtenth.com';
    case 'staging':
      return 'https://api.staging.wtenth.com';
    case 'local':
      return 'http://localhost:3000';
    default:
      return 'https://api.staging.wtenth.com';
  }
};

const config = {
  bffUri: getBffUri(),
  localhostUri: 'http://localhost:8000',
};

export default config;
