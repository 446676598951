import React from 'react';

import Logo from 'images/logo.svg';

import { useVersion, useCurrentUser } from 'hooks';

import * as S from './styles';

type Props = {};

const Header: React.FC<Props> = () => {
  const versionInfo = useVersion();

  const user = useCurrentUser();

  return (
    <S.Wrap>
      <S.Logo src={Logo} alt="West10 logo" />
      <S.VersionInfo>{versionInfo?.version ?? 'vX.X.X'}</S.VersionInfo>
      <S.Line />
      <S.MainMenu>
        <p>There is no limit to what we, as women, can accomplish!</p>
        <S.NameWrap>
          {user?.displayName && <p>Hi, {user.displayName}!</p>}
          {user?.photoURL && <S.ProfileImg src={user.photoURL} alt="User photo" />}
        </S.NameWrap>
      </S.MainMenu>
    </S.Wrap>
  );
};

export default Header;
