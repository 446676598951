import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import * as S from './styles';

interface Props extends Partial<LoadingComponentProps> {
  height?: string | number | undefined;
}

const Loading: React.FunctionComponent<Props> = ({ height = '100%' }: Props) => (
  <S.Wrap height={height}>
    <S.Spin size="large" />
  </S.Wrap>
);

export default Loading;
