import { Sorting } from 'store';

const graphqlSorting = (tableSort: boolean | 'descend' | 'ascend' | undefined) => {
  let graphqlSort: Sorting | undefined = undefined;
  switch (tableSort) {
    case 'ascend':
      graphqlSort = Sorting.Asc;
      break;
    case 'descend':
      graphqlSort = Sorting.Desc;
      break;
  }
  return graphqlSort;
};

export default graphqlSorting;
