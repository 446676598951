import { ComponentType } from 'react';
import Loadable, { LoadingComponentProps } from 'react-loadable';
import Loading from 'components/Loading';

export const Dashboard = Loadable({
  loader: () => import('pages/Dashboard' /* webpackChunkName: "dashboard" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Login = Loadable({
  loader: () => import('pages/Login' /* webpackChunkName: "login" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Orders = Loadable({
  loader: () => import('pages/Orders' /* webpackChunkName: "Orders" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Storefronts = Loadable({
  loader: () => import('pages/Storefronts' /* webpackChunkName: "storefronts" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const StorefrontDetail = Loadable({
  loader: () => import('pages/StorefrontDetail' /* webpackChunkName: "storefrontDetail" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Users = Loadable({
  loader: () => import('pages/Users' /* webpackChunkName: "users" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Discounts = Loadable({
  loader: () => import('pages/Discounts' /* webpackChunkName: "discounts" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Tags = Loadable({
  loader: () => import('pages/Tags' /* webpackChunkName: "tags" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});

export const Messages = Loadable({
  loader: () => import('pages/Messages' /* webpackChunkName: "tags" */),
  loading: Loading as ComponentType<LoadingComponentProps>,
});
