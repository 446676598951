import React, { useCallback, useState, useEffect } from 'react';
import { compose } from 'recompose';

import { Menu } from 'antd';
import MenuLink from 'components/MenuLink';
import { logout } from 'utils';
import client from 'store/apolloClient';
import LogOutIcon from 'images/log-out.svg';
import NotificationsModal from 'components/NotificationsModal';
import CategoriesModal from 'components/CategoriesModal';

import { withErrorBoundary } from 'components/ErrorBoundary';
import { useRouter } from 'hooks';
import * as S from './styles';
import { useAuthUserQuery, UserType } from 'store';
import HappyWomensDay from 'components/HappyWomensDay';
import { useTwilio } from 'services/Twilio';

type Props = {};

type Item = {
  title: string;
  link: string;
  key?: string;
  icon?: string;
};

type ParentItem = {
  title: string;
  key: string;
  children: Item[];
};

const menu: (Item | ParentItem)[] = [
  { link: '/dashboard', title: 'Dashboard', icon: 'area-chart' },
  { link: '/storefronts', title: 'Storefronts', icon: 'area-chart' },
  { link: '/users', title: 'Users', icon: 'area-chart' },
  { link: '/orders', title: 'Orders', icon: 'area-chart' },
  { link: '/messages', title: 'Messages', icon: 'area-chart' },
  { link: '/discounts', title: 'Discounts', icon: 'area-chart' },
  { link: '/tags', title: 'Tags Library', icon: 'area-chart' },
];
const ambassadorMenu: (Item | ParentItem)[] = [
  { link: '/storefronts', title: 'Storefronts', icon: 'area-chart' },
];

const Sidebar: React.FC<Props> = () => {
  const { location } = useRouter();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [categoriesVisible, setCategoriesVisible] = useState(false);
  const [pageSelected, setPageSelected] = useState<string>(location.pathname);
  const { data: authQuery } = useAuthUserQuery();
  const isAmbassador = authQuery?.me?.type === UserType.Ambassador;
  const { twilioOnLogOut } = useTwilio();

  useEffect(() => {
    setPageSelected(location.pathname);
  }, [location.pathname]);

  const renderItem = (item: Item, key: number) => (
    <Menu.Item key={item.link}>
      <MenuLink
        name={item.title}
        to={item.link}
        className={undefined}
        iconName={undefined}
        iconStyles={undefined}
      />
    </Menu.Item>
  );

  const ambassadorSidebar = (
    <S.Wrap>
      <Menu
        onClick={(e) => setPageSelected(e.key)}
        className="navigation"
        mode="inline"
        selectedKeys={[pageSelected]}
        inlineIndent={0}
      >
        {isAmbassador
          ? ambassadorMenu.map((item, key) => renderItem(item as Item, key))
          : menu.map((item, key) => renderItem(item as Item, key))}
      </Menu>
      <HappyWomensDay />
      <S.Logout
        onClick={useCallback(() => {
          logout(client);
          try {
            twilioOnLogOut();
          } catch (error) {
            console.log('Call to twilioOnLogOut failed!');
          }
        }, [twilioOnLogOut])}
      >
        <S.LogoutIcon src={LogOutIcon} alt="Log Out icon" />
        <S.LogoutText> Log Out </S.LogoutText>
      </S.Logout>
    </S.Wrap>
  );

  const adminSidebar = (
    <S.Wrap>
      <Menu
        onClick={(e) => setPageSelected(e.key)}
        className="navigation"
        mode="inline"
        selectedKeys={[pageSelected]}
        inlineIndent={0}
      >
        {menu.map((item, key) => renderItem(item as Item, key))}
        <Menu.Item
          key="/notification"
          onClick={useCallback(() => setNotificationsVisible(true), [])}
        >
          <MenuLink
            name="Notification"
            to="#"
            className={undefined}
            iconName={undefined}
            iconStyles={undefined}
          />
        </Menu.Item>
        <Menu.Item key="/categories" onClick={useCallback(() => setCategoriesVisible(true), [])}>
          <MenuLink
            name="Categories"
            to="#"
            className={undefined}
            iconName={undefined}
            iconStyles={undefined}
          />
        </Menu.Item>
        <CategoriesModal
          visible={categoriesVisible}
          onClose={useCallback(() => setCategoriesVisible(false), [])}
        />
        <NotificationsModal
          visible={notificationsVisible}
          onClose={useCallback(() => setNotificationsVisible(false), [])}
        />
      </Menu>
      <HappyWomensDay />
      <S.Logout onClick={useCallback(() => logout(client), [])}>
        <S.LogoutIcon src={LogOutIcon} alt="Log Out icon" />
        <S.LogoutText> Log Out </S.LogoutText>
      </S.Logout>
    </S.Wrap>
  );

  return isAmbassador ? ambassadorSidebar : adminSidebar;
};

export default compose<Props, {}>(withErrorBoundary)(Sidebar);
