export const colors = {
  white: '#ffffff',
  whiteDark: '#F3F3F3',
  black: '#000000',
  green: '#06b359',
  red: '#ff2b2b',
  redLight: 'rgba(191,117,113,0.1)',
  redDark: '#CCB98C',
  greyWhite: '#f7f7f7',
  greyLight: '#f2f3f3',
  greyMiddle: '#e3e3e3',
  grey: '#7f7f7f',
  customGrey: '#3D3F40',
  greyDark: '#292c39',
  greyBlack: '#161616',
  gold: '#ffb533',
  goldDark: '#d48700',
  sectionBorder: '1px solid #ddd',
  primaryHover: '#d8dbe0',
  darkHover: '161616',
  secondary: '#CCB98C',
  primary: '#3D3F40',
  error: '#b00020',
  primarytransparency: 'rgba(61, 63, 64, 0.6)',
  brandattention: '#BF7571',
  forrest: '#04524A',
  blush: '#E9E5E0',
  errorred: '#EB5757',
  successgreen: '#069687',
  grey2: '#4F4F4F',
};

export const layout = {
  breakpointMobile: '576px',
  breakpointTablet: '768px',
  sidebarWidth: '200px',
};
