import { useState, useEffect } from 'react';
import fetchVersion, { VersionInfo } from '../utils/version';

const useVersion = (): VersionInfo | undefined => {
  const [version, setVersion] = useState();

  useEffect(() => {
    fetchVersion().then(setVersion);
  }, []);

  return version;
};

export default useVersion;
