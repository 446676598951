import * as React from 'react';
// import Raven from 'raven-js';
import { ErrorInfo } from './types';
import AppError from './AppError';

type Props = {
  fallbackComponent: React.ComponentType<{ error: Error; info: ErrorInfo }>;
  onError: (error: Error, info: ErrorInfo) => void;
};

type State = {
  error: Error | null;
  info: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps: Props = {
    onError: () => undefined,
    fallbackComponent: AppError,
  };

  state: State = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      error: error,
      info: info,
    });

    // Raven.captureException(error, {
    //   extra: {
    //     info
    //   }
    // });
    this.props.onError(error, info);
  }

  render() {
    const { error, info } = this.state;

    if (error && info) return React.createElement(this.props.fallbackComponent, { error, info });
    return this.props.children;
  }
}

export default ErrorBoundary;

export const withErrorBoundary = (Component: any) => (props: any) =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
