import React from 'react';

import * as S from './styles';

type Props = {};

const HappyWomensDay: React.FC<Props> = () => {
  const now = new Date();
  if (now.getMonth() === 2 && now.getDate() === 8) {
    return (
      <S.Wrap>
        <div
          style={{
            width: '100%',
            height: 0,
            paddingBottom: '89%',
            position: 'relative',
          }}
        >
          <iframe
            src="https://giphy.com/embed/qLOGJRzWVUFY4"
            width="100%"
            height="100%"
            title="Women's Day"
            style={{ position: 'absolute' }}
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </div>
      </S.Wrap>
    );
  }

  return null;
};

export default HappyWomensDay;
