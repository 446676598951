import styled from 'styled-components';
import { Menu } from 'antd';
import { colors } from 'theme';

export const Wrap = styled.div`
  /* width: 270px; */
  width: 190px;
  background-color: ${colors.white} !important;
  padding-top: 32px;
  box-shadow: 8px 0 10px -6px black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  height: 93vh;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Navigation = styled(Menu)`
  background-color: ${colors.white};
  width: calc(100% + 1px);

  & .ant-menu-item {
    width: 100%;
    margin: 10px 0 !important;
    background-color: ${colors.white};

    a {
      color: #3d3f40;
    }
  }

  & .ant-menu-item:hover,
  & .ant-menu-item.active {
    background-color: rgba(191, 117, 113, 0.1);

    a {
      color: #bf7571;
    }
  }

  & .ant-menu-submenu-title {
    width: 100%;
    margin: 0;
    font-size: 12px;
    color: #9ca0a4;
    background-color: ${colors.greyLight};
    align-items: center;
    cursor: unset;
    i {
      display: none;
    }
  }

  & .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 24px !important;
  }

  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${colors.greyBlack};
    a {
      color: ${colors.white};
    }
  }

  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after {
    display: none;
  }
`;

export const Logo = styled.img``;

export const Logout = styled.div`
  width: 100;
  margin-bottom: 20px;
  color: #3d3f40;
  cursor: pointer;
`;

export const LogoutText = styled.div`
  font-size: 16px;
  line-height: 20px;

  margin: auto 0;
  margin-left: 60px;

  &:hover {
    color: black;
  }
`;

export const LogoutIcon = styled.img`
  position: absolute;
  margin-left: 24px;
`;
