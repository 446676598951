import React, { createContext, useContext, useState, useEffect } from 'react';
import twilio, { Client } from '@twilio/conversations';
import config from 'config';
import useCurrentUser from 'hooks/useCurrentUser';

type TwilioProviderProps = {
  twilioToken: string | undefined;
  twilioClient: Client | undefined;
  twilioClientState: twilio.State | undefined;
  twilioOnLogIn: () => Promise<void>;
  twilioOnLogOut: () => Promise<void>;
};

// Code from mobile app Twilio service
const TwilioContext = createContext<TwilioProviderProps>({
  twilioToken: undefined,
  twilioClient: undefined,
  twilioClientState: undefined,
  twilioOnLogIn: async () => {
    // linting needs something here. Code implemented in ContextProvider
  },
  twilioOnLogOut: async () => {
    // linting needs something here. Code implemented in ContextProvider
  },
  // twilioConversations: undefined,
});
const BASE_API_URL = config.bffUri;
const TWILIO_TOKEN_URI = '/twilio/conversations/admin';

const TwilioContextProvider = ({ children }: { children: React.ReactNode }) => {
  const uCurrentUser = useCurrentUser();
  const [twilioToken, setTwilioToken] = useState<string | undefined>(undefined);
  const [twilioClient, setTwilioClient] = useState<Client | undefined>(undefined);
  const [twilioClientState, setTwilioClientState] = useState<twilio.State | undefined>(undefined);
  const [idToken, setIdToken] = useState<string>('');

  useEffect(() => {
    uCurrentUser?.getIdToken()?.then((tokenreturned) => setIdToken(tokenreturned));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, uCurrentUser?.uid]);

  useEffect(() => {
    callTwilioSetup();
    // NOTE: copied from mobile-app code, only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  const getTwilioToken = async () => {
    const currentUser = uCurrentUser;
    try {
      if (!currentUser?.uid) {
        console.log('no currentUser');
        return;
      }
      const userIdToken = await currentUser?.getIdToken();

      const result = await fetch(`${BASE_API_URL}${TWILIO_TOKEN_URI}`, {
        method: 'GET',
        headers: {
          Authorization: userIdToken,
        },
      }).then((res) => res.json());
      setTwilioToken(result?.token);
      return result?.token;
    } catch (error) {
      console.log('error in getTwilioToken ' + error);
    }
  };

  const callTwilioSetup = async () => {
    try {
      const resultToken = await getTwilioToken();
      const _twilioToken = resultToken;
      if (!_twilioToken) {
        console.log('no _twilioToken ');
        return;
      }

      const _twilioClient = new Client(_twilioToken);
      _twilioClient.on('stateChanged', (state) => {
        setTwilioClientState(state);
      });

      _twilioClient.on('tokenAboutToExpire', async () => {
        console.log('about to expire');
        const taeTwilioToken = await getTwilioToken();
        twilioClient?.updateToken(taeTwilioToken);
      });
      setTwilioClient(_twilioClient);
    } catch (error) {
      console.log('Twilio set up failed ' + error);
    }
  };

  const twilioOnLogIn = async () => {
    callTwilioSetup();
  };

  const twilioOnLogOut = async () => {
    await twilioClient?.shutdown();
    setTwilioToken(undefined);
    setTwilioClientState(undefined);
    setTwilioClient(undefined);
  };

  return (
    <TwilioContext.Provider
      value={{
        twilioClient,
        twilioToken,
        twilioClientState,
        twilioOnLogIn,
        twilioOnLogOut,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export default TwilioContextProvider;

export const useTwilio = () => useContext(TwilioContext);

export function withTwilio<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  return (props: P) => {
    return (
      <TwilioContext.Consumer>
        {(context) => {
          return <Component TwilioContext={context} {...props} />;
        }}
      </TwilioContext.Consumer>
    );
  };
}
