import React from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';

import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import HttpsRedirect from 'react-https-redirect';
import client from './store/apolloClient';
// import Raven from 'raven-js';
// import * as favIcon from 'images/favicon.ico';
import App from './containers/App';
import TwilioService from './services/Twilio';
import * as serviceWorker from './serviceWorker';

import './theme/index.css';

const Index = () => {
  return (
    <>
      <Helmet>{/* <link rel="shortcut icon" href={favIcon} /> */}</Helmet>
      <Router>
        <ApolloProvider client={client}>
          <TwilioService>
            <HttpsRedirect>
              {/* <ShowSidebarProvider> */}
              <App />
              {/* </ShowSidebarProvider> */}
            </HttpsRedirect>
          </TwilioService>
        </ApolloProvider>
      </Router>
    </>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
